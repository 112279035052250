<p *ngIf="langue.l == 'fr'">
    Les Cartes de Destinée sont représentés par des cartes d'un jeu de 54 cartes.<br/>
    4 couleurs, les nombres 2 à 10, valet, dame, roi, as, ainsi que les deux jokers.<br/>
    Ils sont donnés aux joueurs pour des raisons diverses, comme bien jouer son personnage,
    pousser les limites de créativité, ou de manière générale pour récompenser un joueur.
    Un joueur ne peut posséder qu'une seule Carte de Destinée à la fois (qu'elle soit retournée ou pas).<br/>
    Quand un joueur reçoit une Carte de Destinée, il reçoit une carte face cachée.
    Retourner une carte et/ou l'activer ne coûte rien et est possible à n'importe quel moment
    (si un joueur veut activer sa carte pendant le tour d'un autre joueur, ce dernier doit donner son accord).<br/>
    Une fois retournée, la carte peut être gardée un temps indéfini avant d'être activée.<br/>
    Quand le joueur veut utiliser la carte, il la retourne (si elle était encore face cachée), la lit et décide de la cible (si applicable).<br/>
    La cible doit être en ligne de vue du personnage, ou bien être à proximité et dont la position est connue par le personnage.<br/>
    <br/>
    Quand un joueur ne possède pas de carte de destiné, il lui est possible -une fois par séance- d'utiliser sa chance pour relancer un de ses jet.
    Cette relance peut inclure le jet de toucher, le jet de dégâts, ou les deux, au choix.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    <br/>
</p>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Nombre</th>
            <th>Coeur</th>
            <th>Carreau</th>
            <th>Trèfle</th>
            <th>Pique</th>
        </tr>
        <tr>
            <td>2</td>
            <td>La cible regagne 2 points d'énergie</td>
            <td>La cible gagne +1 au toucher**</td>
            <td>La cible gagne +1 aux dégâts**</td>
            <td>La cible perd 2 points d'énergie</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Une blessure de la cible est soigné de 2</td>
            <td>La cible gagne +2 au toucher**</td>
            <td>La cible gagne +2 aux dégâts**</td>
            <td>La cible prend 2 points de douleur jusqu'au prochain repos</td>
        </tr>
        <tr>
            <td>4</td>
            <td>La cible regagne 3 points d'énergie</td>
            <td>La cible gagne +3 au toucher**</td>
            <td>La cible gagne +3 aux dégâts**</td>
            <td>La cible perd 3 points d'énergie</td>
        </tr>
        <tr>
            <td>5</td>
            <td>Une blessure de la cible est soigné de 3</td>
            <td>La cible prend -1 au toucher**</td>
            <td>La cible prend -1 aux dégâts**</td>
            <td>La cible prend 3 points de douleur jusqu'au prochain repos</td>
        </tr>
        <tr>
            <td>6</td>
            <td>Un objet est découvert</td>
            <td>La cible prend -2 au toucher**</td>
            <td>La cible prend -2 aux dégâts**</td>
            <td>Un objet se casse</td>
        </tr>
        <tr>
            <td>7</td>
            <td>La cible regagne 5 points d'énergie</td>
            <td>La cible prend -3 au toucher**</td>
            <td>La cible prend -3 aux dégâts**</td>
            <td>La cible perd 5 points d'énergie</td>
        </tr>
        <tr>
            <td>8</td>
            <td>Une blessure de la cible est soigné de 5</td>
            <td>Un animal allié arrive</td>
            <td>Un animal hostile arrive</td>
            <td>La cible prend 5 points de douleur jusqu'au prochain repos</td>
        </tr>
        <tr>
            <td>9</td>
            <td>La cible gagne 3 PA</td>
            <td>Tu apprends les choses suivantes sur la cible :<br/>
                les magies pures et combinées, les -PA, PR, énergie et douleur- max et actuels</td>
            <td>Tu apprends les choses suivantes sur la cible :<br/>
                les magies pures et combinées, les -PA, PR, énergie et douleur- max et actuels</td>
            <td>La cible perd 3 PA</td>
        </tr>
        <tr>
            <td>10</td>
            <td>La cible gagne la vision dans le noir<br/>
                pour 1 heure</td>
            <td>Un NPC allié apparaît</td>
            <td>Un tentacule apparaît et slap la créature la plus proche, puis disparaît</td>
            <td>La cible a sa vision troublée<br/>
                pour 1 minute</td>
        </tr>
        <tr>
            <td>J</td>
            <td>La cible gagne une de ses visions élémentaires gratuitement<br/>
                pour 10 minutes</td>
            <td>La cible est capable de faire une manipulation d'air*</td>
            <td>La cible est capable de faire une manipulation d'eau*</td>
            <td>La cible vomit<br/>
                pour 1 tour</td>
        </tr>
        <tr>
            <td>Q</td>
            <td>La prochaine manipulation de la cible gagne +1d4</td>
            <td>La cible est capable de faire une manipulation de nature*</td>
            <td>La cible est capable de faire une manipulation de feu*</td>
            <td>La cible est paralysée<br/>
                pour 1 tour</td>
        </tr>
        <tr>
            <td>K</td>
            <td>L'invocation est gratuite en énergie<br/>
                pour 1 tour pour la cible</td>
            <td>La cible est capable de faire une manipulation de lumière*</td>
            <td>La cible est capable de faire une manipulation de ténèbre*</td>
            <td>L'invocation est impossible<br/>
                pour 1 tour pour la cible</td>
        </tr>
        <tr>
            <td>A</td>
            <td>Explosion</td>
            <td>Tire 2 nouvelles cartes<br/>
                et active-les immédiatement</td>
            <td>Tire 2 nouvelles cartes<br/>
                et active-les immédiatement</td>
            <td>Explosion</td>
        </tr>
        <tr>
            <td>Joker</td>
            <td>-</td>
            <td>Au choix dans tout le tableau<br/>
                ou un autre effet similaire</td>
            <td>Au choix dans tout le tableau<br/>
                ou un autre effet similaire</td>
            <td>-</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Number</th>
            <th>Heart</th>
            <th>Diamond</th>
            <th>Clubs</th>
            <th>Spade</th>
        </tr>
        <tr>
            <td>2</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>3</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>4</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>5</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>6</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>7</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>8</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>9</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>10</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>J</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>Q</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>K</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>A</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
    </table>
</div>

<p *ngIf="langue.l == 'fr'">
    * = Cette manipulation ne coûte pas de PA ni d'énergie,
    et est possible même si la cible n'a pas d'affinité à cette magie.<br/>
    Les dés sont 4d4 sans modificateurs (même si la cible possède
    de la spécialisation dans la magie correspondante, et même si la cible est fatiguée).<br/>
    Une fois lancés, ces dés ne peuvent être modifiés par aucune source, ni être relancés.
    Ces dés ne peuvent pas être enlevés par de la perturbation, ni augmentés par une compétence magique.<br/>
    La manipulation doit être effectuée dans la minute, après quoi l'effet se dissipe.<br/>
    Quand la manipulation est effectuée, l'effet se dissipe.<br/>
    <br/>
    ** = Ce bonus s'applique à la prochaine attaque de la cible, et s'efface ensuite.
    L'effet n'est valable que pour une attaque (pas pour un tour complet), mais n'a pas de date d'expiration une fois appliqué sur la cible.<br/>
    La cible (in-game) n'est pas au courant de cet effet et ne ressent rien de spécial.<br/>
    RP : cette mofidication peut être considérée comme un coup de chance ou de malchance, ou que le destin en a décidé ainsi.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    <br/>
</p>
