<br/>
<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Valeur</th>
            <th>Efficacité</th>
            <th>Précision</th>
            <th>Taille</th>
            <th>Distance</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Légère / visuelle</td>
            <td>Aléatoire / minimale pour exister</td>
            <td>Paume (5 cm)</td>
            <td>Contact*</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Basique</td>
            <td>Basique (2 m)</td>
            <td>Main (20 cm)</td>
            <td>1 case**</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Utile</td>
            <td>Utile (1 pas)</td>
            <td>Bras (1 pas)</td>
            <td>2-4 cases</td>
        </tr>
        <tr>
            <td>4</td>
            <td>Moyenne</td>
            <td>Forte (20 cm)</td>
            <td>Corps (2 m / 1 case)</td>
            <td>5-10 cases</td>
        </tr>
        <tr>
            <td>5</td>
            <td>Moyenne+</td>
            <td>Très forte (5 cm)</td>
            <td>Groupe (5-10 m / 2-6 cases)</td>
            <td>11-20 cases</td>
        </tr>
        <tr>
            <td>6</td>
            <td>Forte</td>
            <td>Extrême (1 cm)</td>
            <td>Très grand (10-20 m / 7-14 cases)</td>
            <td>21-50 cases</td>
        </tr>
        <tr>
            <td>7</td>
            <td>Très forte</td>
            <td>+</td>
            <td>21-50 m / 15-34 cases</td>
            <td>51-100 cases</td>
        </tr>
        <tr>
            <td>8</td>
            <td>Très très forte</td>
            <td>+</td>
            <td>51-100 m / 35-64 cases</td>
            <td>101-200 cases</td>
        </tr>
        <tr>
            <td>9</td>
            <td>++</td>
            <td>++</td>
            <td>101-200 m / 65-134 cases</td>
            <td>201-500 cases</td>
        </tr>
        <tr>
            <td>10+</td>
            <td>Extrême</td>
            <td>++</td>
            <td>200+ m / 135+ cases</td>
            <td>501+ cases</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Valeur</th>
            <th>Efficacité</th>
            <th>Précision</th>
            <th>Taille</th>
            <th>Distance</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Légère / visuelle</td>
            <td>Aléatoire / minimale pour exister</td>
            <td>Paume (5 cm)</td>
            <td>Contact*</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Basique</td>
            <td>Basique (2 m)</td>
            <td>Main (20 cm)</td>
            <td>1 case**</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Utile</td>
            <td>Utile (1 pas)</td>
            <td>Bras (1 pas)</td>
            <td>2-4 cases</td>
        </tr>
        <tr>
            <td>4</td>
            <td>Forte</td>
            <td>Forte (20 cm)</td>
            <td>Corps (2 m)</td>
            <td>5-10 cases</td>
        </tr>
        <tr>
            <td>5</td>
            <td>Très forte</td>
            <td>Très forte (5 cm)</td>
            <td>Groupe (5-10 m)</td>
            <td>11-20 cases</td>
        </tr>
        <tr>
            <td>6+</td>
            <td>Extrême</td>
            <td>Extrême (1 cm)</td>
            <td>Très grand (20 m)</td>
            <td>21+ cases</td>
        </tr>
    </table>
</div>

<p *ngIf="langue.l == 'fr'">
    *= contact quasi impossible avec un ennemi en combat<br/>
    **= distance d’attaque mêlée “classique”<br/>
</p>
<p *ngIf="langue.l == 'en'">
    *= contact almost impossible with an enemy in combat<br/>
    **= "classic" melee attack range<br/>

</p>

<p *ngIf="langue.l == 'fr'">
    Note : Les valeurs du tableau ne sont là que comme valeur indicative.
    Les résultats réels vont dépendre de la magie et de l'utilisation voulue.<br/>
    De plus, le tableau s'arrête à 6 mais les valeurs continuent.
</p>
<p *ngIf="langue.l == 'en'">
    Note : The values in the table are only there as indications.
    The real results will depend on the magic and the goal.<br/>
    Also, the table stops at 6 but the values continue.
</p>