<p *ngIf="langue.l == 'fr'">
    Des personnes entraînées peuvent cibler des parties du corps.<br/>
    /!\ Page en construction
</p>
<p *ngIf="langue.l == 'en'">
    Trained characters can target parts of the body.<br/>
    /!\ Page in construction
</p>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Cible</th>
            <th>Malus au toucher</th>
            <th>Résultat additionnels</th>
            <th>Détails des résultat additionnels</th>
        </tr>
        <tr>
            <td>Tête</td>
            <td>-2</td>
            <td>étourdissement</td>
            <td>
                Si les dégâts infligés sont strictement plus grands que la résistance de la victime,<br/>
                alors la victime perd la moitié de ses PA au prochain tour.
            </td>
        </tr>
        <tr>
            <td>Torse</td>
            <td>-</td>
            <td>extra bleeding</td>
            <td>
                L'intensité de la blessure est augmentée de 1 (si de type coupant ou perçant).<br/>
                Ainsi l'intensité de la blessure est au même niveau que les points de douleur correspondant.
            </td>
        </tr>
        <tr>
            <td>Bras</td>
            <td>-1</td>
            <td>diffcultés pour manipuler</td>
            <td>
                Si les dégâts infligés sont strictement plus grands que la résistance de la victime,<br/>
                alors la victime subit un -1 à tous ses jets d'attaque.
            </td>
        </tr>
        <tr>
            <td>Main</td>
            <td>-3</td>
            <td>peut lâcher son arme</td>
            <td>
                Si les dégâts infligés sont strictement plus grands que la résistance de la victime,<br/>
                alors la victime perd contrôle de la main cible et lâche ce qu'elle tenait.
            </td>
        </tr>
        <tr>
            <td>Jambe</td>
            <td>-1</td>
            <td>difficultés pour bouger</td>
            <td>
                Si les dégâts infligés sont strictement plus grands que la résistance de la victime,<br/>
                alors la victime ne peut plus courir ni sprinter.
            </td>
        </tr>
        <tr>
            <td>Genou</td>
            <td>-3</td>
            <td>empêche de bouger</td>
            <td>
                Si les dégâts infligés sont strictement plus grands que la résistance de la victime,<br/>
                alors la victime ne peut plus se déplacer.
            </td>
        </tr>
        <tr>
            <td>Pieds</td>
            <td>-2</td>
            <td>difficultés pour bouger</td>
            <td>
                Si les dégâts infligés sont strictement plus grands que la résistance de la victime,<br/>
                alors la victime ne peut plus courir ni sprinter.
            </td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Target</th>
            <th>To hit</th>
            <th>Additional results</th>
        </tr>
        <tr>
            <td>Head</td>
            <td>-2</td>
            <td>stun</td>
        </tr>
        <tr>
            <td>Torso</td>
            <td>-</td>
            <td>extra bleeding</td>
        </tr>
        <tr>
            <td>Arm</td>
            <td>-1</td>
            <td>diffculties to manipulate</td>
        </tr>
        <tr>
            <td>Hand</td>
            <td>-3</td>
            <td>can drop their weapon</td>
        </tr>
        <tr>
            <td>Leg</td>
            <td>-1</td>
            <td>difficulties to move</td>
        </tr>
        <tr>
            <td>Knee</td>
            <td>-3</td>
            <td>prevents moving</td>
        </tr>
        <tr>
            <td>Foot</td>
            <td>-2</td>
            <td>difficulties to move</td>
        </tr>
    </table>
</div>
