<p *ngIf="langue.l == 'fr'">
    Les lyunes sont pas d'accord sur comment organiser leur peuple => gueguerre<br/>
    Pendant ce temps instable, un groupe d'une vingtaine de Lyune part vers l'est.<br/>
    guerre résolue quand des gens de plusieurs magies et plusieurs backgrounds/métiers se réunissent pour parler. Le premier conseil est formé.<br/>
    Petit à petit les Lyunes joinent cette coalition et le gouvernement devient une oligarchie démocratique.<br/>
    Le groupe lyune parti arrive au couloir laissé par la guerre des elfs.<br/>
    Ils croisent un esprit de la forêt qui les bénit et ils sont transformés. Les premiers Etreks sont nés.<br/>
    <br/>
    <b>Politique</b><br/>
    Lyune = oligarchie démocratique<br/>
    Nekali = monarchie héréditaire
</p>
<p *ngIf="langue.l == 'en'">

</p>