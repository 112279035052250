<button class="button back-button">
    <a routerLink="/colours-of-magic/uses">
        <i class="fa-sharp fa-solid fa-caret-left"></i>
        Retour
    </a>
</button>

<div class="magic-pres">
    <div class="magic-title">
        Terre
    </div>
    <div class="magic-stats">
        <div class="magic-purity">
            <div class="magic-inactive">pure</div>
            <div class="magic-active">combinée</div>
        </div>
        <div class="magic-tangibility">
            <div class="magic-active">Tangible</div>
            <div class="magic-inactive">intangible</div>
        </div>
        <div class="magic-det">
            <div>DET</div>
            <div>3</div>
        </div>
    </div>
</div>

<p></p>
