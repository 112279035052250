<h3 *ngIf="langue.l == 'fr'">Toucher</h3>
<h3 *ngIf="langue.l == 'en'">Hitting</h3>

<p *ngIf="langue.l == 'fr'">
    Attaque : <b>1d12 + stat</b> (force ou dextérité selon armes)<br/>
    Esquive : <b>1d12 + agilité</b><br/>
    Si esquive strictement plus que attaque = esquivé => aucun dégâts<br/>
    Si attaque est égale ou plus que esquive = touché => lance les dégâts<br/>
</p>
<p *ngIf="langue.l == 'en'">
    Attack : <b>1d12 + stat</b> (strength or dexterity depending on the weapon type)<br/>
    Evade : <b>1d12 + agility</b><br/>
    If the evade score is higher than the attack score, then the attack misses and the attack deals no damage.<br/>
    If the attack score is equal to or higher than the evade score, then the attack hits, and the attacker can roll for damage.<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Résultats spéciaux</h3>
<h3 *ngIf="langue.l == 'en'">Special results</h3>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Attaque \ Évasion</th>
            <td>1</td>
            <td>2-11</td>
            <td>12</td>
        </tr>
        <tr>
            <td>1</td>
            <td>Échec + cible distraite</td>
            <td>Échec</td>
            <td>Échec + attaquant distrait<br/>
                (-2 esquive jusqu'au début de son prochain tour)</td>
        </tr>
        <tr>
            <td>2-11</td>
            <td>Réussite</td>
            <td>-</td>
            <td>Échec</td>
        </tr>
        <tr>
            <td>12</td>
            <td>Réussite + continuation + coup critique<br/>
                (ignore résistance naturelle)</td>
            <td>Réussite + continuation</td>
            <td>Réussite</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Attack \ Evasion</th>
            <td>1</td>
            <td>2-11</td>
            <td>12</td>
        </tr>
        <tr>
            <td>1</td>
            <td>Failure + target is distracted</td>
            <td>Failure</td>
            <td>Failure + attacker is distracted<br/>
                (-2 evade until the start of his next turn)</td>
        </tr>
        <tr>
            <td>2-11</td>
            <td>Success</td>
            <td>-</td>
            <td>Failure</td>
        </tr>
        <tr>
            <td>12</td>
            <td>Success + continuation + critical hit<br/>
                (ignores natural resistance)</td>
            <td>Success + continuation</td>
            <td>-</td>
        </tr>
    </table>
</div>

<h3 *ngIf="langue.l == 'fr'">Attaques à distance</h3>
<h3 *ngIf="langue.l == 'en'">Ranged attacks</h3>

<p *ngIf="langue.l == 'fr'">
    Les attaques à distance ont besoin d'une ligne de vue ininterrompue pour ne pas être handicappées.<br/>
    Si la ligne entre l'attaquant et la cible traverse le token d'une créature de tout son diamètre, alors la ligne de vue est considérée complètement interrompue et l'attaque impossible.
    Si la ligne entre l'attaquant et la cible traverse le token d'une créature de moins de la moitié de son diamètre, alors l'attaque subit un malus de 3 au toucher.
    Si la ligne entre l'attaquant et la cible traverse la case d'une créature sans toucher son token, alors l'attaque subit un malus de 1 au toucher.<br/>
    L'environnement peut aussi perturber la ligne de vue en fournissant du couvert, allant de -1 au -3 au toucher, voire casser la ligne et rendre l'attaque impossible.
    L'impact dépend de la taille du couvert, et certains matériaux peuvent être plus fragiles que d'autres.
</p>
<p *ngIf="langue.l == 'en'">
    <br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Continuation</h3>
<h3 *ngIf="langue.l == 'en'">Continuation</h3>

<p *ngIf="langue.l == 'fr'">
    La continuation correspond à l'opportunité d'effectuer une nouvelle attaque contre le même ennemi.<br/>
    Certaines compétences donnent une possibilité de continuation selon les situations, ainsi que selon les jets d'attaque et de défense.
    La continuation n'est possible qu'avec une arme de mélée.<br/>
    La nouvelle attaque coûte 1 de moins que la précédente (min 1), et le prix peut être payé avec
    un mélange de PA, PR et d'énergie. Dans le cas d'une continuation en tant que Réaction, le prix doit être payé en PR ou énergie (mais pas PA).<br/>
    Pour chaque nouvelle attaque, il faut relancer les jets de toucher, d'esquive, et de dégâts.<br/>
    N'est pas limité à une fois par tour, mais chaque attaque ne peut générer qu'une seule continuation.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    Continuation corresponds to the opportunity to attack again against the same enemy.<br/>
    Some skills offer a possibility for continuation depending on the situation, as well as depending on the attack and defense rolls.
    Continuation is only possible with melee weapons.<br/>
    This new attack costs 1 less than the previous (min 1), and this price can be paid with
    a mix of AP, RP and energy. In the case of a continuation as a Reaction, the price must be paid with RP or energy (and not AP).<br/>
    New rolls are needed for every additional attack (to hit, to evade, and the damage).<br/>
    Not limited to once per turn.<br/>
</p>
