<nav class="band band3">
    <a routerLink="/system/attacking/hitting" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Toucher</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Hitting</menu-button>
    </a>
    <a routerLink="/system/attacking/damages" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Dégâts</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Damages</menu-button>
    </a>
    <a routerLink="/system/attacking/targeting" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Cibler</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Targeting</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>