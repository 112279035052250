<nav class="band band2">
    <a routerLink="/more-rules/action-flowchart" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Schéma des PA/PR</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Action flowchart</menu-button>
    </a>
    <a routerLink="/more-rules/destiny" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Points de Destinée</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Destiny Points</menu-button>
    </a>
    <a routerLink="/more-rules/cheat-sheet" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Tous les tableaux</menu-button>
        <menu-button *ngIf="langue.l == 'en'">All the tables</menu-button>
    </a>
</nav>
<p>Ces règles sont en cours d'écriture et vont bientôt être rangées dans leur catégorie correspondante. Ou pas. On verra.</p>
-----------------------------------------------------------------------------------------------------
<router-outlet></router-outlet>