<p *ngIf="langue.l == 'fr'">
    Chaque être vivant possède une jauge d'énergie et une jauge de douleur.<br/>
    La jauge de douleur commence à 0 et peut monter jusqu'à son maximum. La jauge d'énergie commence à son maximum et peut descendre jusqu'à 0.<br/>
    Le maximum de ces jauges varie selon l'espèce de la créature, l'individu, et d'autres facteurs (comme certaines compétences).<br/>
    En général, les humains ont un maximum de 10 sur les deux jauges.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    Every living being possesses an energy gauge and a pain gauge.<br/>
    The pain gauge starts at 0 and can fill up to its maximum. The energy gauge starts at its maximum and can decrease to 0.<br/>
    The maximum of these gauges varies depending on the species, the individual, as well as other factors (like certain skills).<br/>
    In general, humans have a maximum of 10 on both gauges.<br/>
</p>

<h3>Tableau pour les humains "par défaut"</h3>
<div class="div-table"  *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Valeurs</th>
            <th>Douleur</th>
            <th>Energie</th>
        </tr>
        <tr>
            <td>10+</td>
            <td>inconscient* pour 1 minute</td>
            <td>-</td>
        </tr>
        <tr>
            <td>9</td>
            <td>blessé (d8) + -2 énergie /10 minutes</td>
            <td>-</td>
        </tr>
        <tr>
            <td>8</td>
            <td>blessé (d8) + -2 énergie /10 minutes</td>
            <td>-</td>
        </tr>
        <tr>
            <td>7</td>
            <td>blessé (d6) + -1 énergie /10 minutes</td>
            <td>-</td>
        </tr>
        <tr>
            <td>6</td>
            <td>blessé (d6) + -1 énergie /10 minutes</td>
            <td>fatigué (-1 magie)</td>
        </tr>
        <tr>
            <td>5</td>
            <td>blessé (d4) (cf <a routerLink="/system/states" routerLinkActive="activebutton">états</a>)</td>
            <td>fatigué (-1 magie)</td>
        </tr>
        <tr>
            <td>4</td>
            <td>blessé (d4) (cf <a routerLink="/system/states" routerLinkActive="activebutton">états</a>)</td>
            <td>fatigué (-1 magie, -1 à tous les jets d12)</td>
        </tr>
        <tr>
            <td>3</td>
            <td>-</td>
            <td>fatigué (-1 magie, -1 à tous les jets d12)</td>
        </tr>
        <tr>
            <td>2</td>
            <td>-</td>
            <td>fatigué (-2 magie, -1 à tous les jets d12 et dégâts)</td>
        </tr>
        <tr>
            <td>1</td>
            <td>-</td>
            <td>fatigué (-2 magie, -1 à tous les jets d12 et dégâts)</td>
        </tr>
        <tr>
            <td>0</td>
            <td>-</td>
            <td>inconscient* pour 1 heure</td>
        </tr>
    </table>
</div>

<div class="div-table"  *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Threshold</th>
            <th>Pain</th>
            <th>Energy</th>
        </tr>
        <tr>
            <td>0</td>
            <td>-</td>
            <td>-</td>
        </tr>
        <tr>
            <td>4</td>
            <td>hurt (d4) (see <a routerLink="/system/states" routerLinkActive="activebutton">states</a>)</td>
            <td>tired (-1 magic)</td>
        </tr>
        <tr>
            <td>6</td>
            <td>hurt (d6) + 1 fatigue /10 minutes</td>
            <td>tired (-1 magic, -1 to every d12 roll)</td>
        </tr>
        <tr>
            <td>8</td>
            <td>hurt (d8) + 2 fatigue /10 minutes</td>
            <td>tired (-2 magic, -1 to every d12 roll and damages)</td>
        </tr>
        <tr>
            <td>10</td>
            <td>unconscious* for 1 minute</td>
            <td>unconscious** for 1 hour</td>
        </tr>
    </table>
</div>

<p *ngIf="langue.l == 'fr'">
  * : Quand le personnage reprend conscience, la jauge de douleur reste à 10 et toute source de douleur le replongera dans un coma.
  Ce coma double en temps à chaque fois que le personnage retombe. Le temps revient à la normale après une semaine passée à être reposé dans un endroit non hostile.<br/>
  ** : Quand le personnage reprend conscience, la jauge d'énergie redescend à 9.
</p>
<p *ngIf="langue.l == 'en'">
    * = when the person regains consciousness, the gauge is changed back to 9.<br/>
    If the person is woken up before the indicated period, they are only half conscious, able to talk and walk, not much else.<br/>
</p>
