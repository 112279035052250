<p *ngIf="langue.l == 'fr'">
    Les elfs sombres se séparent progressivement du gouvernement sylvain et veulent leur indépendance.<br/>
    Les elfs sylvains veulent les garder sous leur pouvoir et les tensions montent.<br/>
    Une tentative de reprise de territoire sombre par les sylvains déclenche l'officialisation de la guerre entre les deux types d'elfs.<br/>
    Les Flyhns restent en majorité neutre dans ce conflit.<br/>
    beaucoup de morts, blessés et destruction.<br/>
    À force, la frontière entre les deux forêts se démarque et un couloir se forme.<br/>
    la forêt du couloir est ravagé, les plantes sont mortes et les animaux rares.<br/>
    Si la guerre continue, l'entièreté de la forêt deviendra comme ça, et les elfs sylvains proposent un traîté.<br/>
    Les elfs sombres gagnent leur indépendance.<br/>
    Pendant la guerre, les flyhns en ont profité pour récupérer le reste des plaines.<br/>
    <br/>
    <b>Politique</b><br/>
    elfs sombres = pas de gouvernement<br/>
    flyhns passent à un monarchie par combat plutôt qu'héréditaire, mais reste majoritairement matriarchique
</p>
<p *ngIf="langue.l == 'en'">

</p>
