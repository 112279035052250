<nav class="band band3">
    <a routerLink="/system/magic/explanation" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Explication</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Explanation</menu-button>
    </a>
    <a routerLink="/system/magic/creation" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Création</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Creation</menu-button>
    </a>
    <a routerLink="/system/magic/manipulation" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Manipulation</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Manipulation</menu-button>
    </a>
    <a routerLink="/system/magic/concentration" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Concentration</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Concentration</menu-button>
    </a>
    <a routerLink="/system/magic/vision" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Vision</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Vision</menu-button>
    </a>
    <a routerLink="/system/magic/conjuration" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Conjuration</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Conjuration</menu-button>
    </a>
    <a routerLink="/system/magic/buffs" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Infusion & Buffs</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Infusion & Buffs</menu-button>
    </a>
    <a routerLink="/system/magic/ballparks" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Ordres de Grandeur</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Ballparks</menu-button>
    </a>
    <a routerLink="/system/magic/group" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Magie de Groupe</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Group Magic</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>
