<h3 *ngIf="langue.l == 'fr'">Propriétés magiques d'un matériau</h3>
<h3 *ngIf="langue.l == 'en'">Magical properties of a material</h3>

<p *ngIf="langue.l == 'fr'">
    La plupart des objets physiques peuvent contenir de l'énergie magique,
    mais certains peuvent en garder plus en quantité, en temps ou en efficacité.<br/>
    De manière générale, plus un matériau est dur, plus il peut garder longtemps de l'énergie,
    plus il est dense, plus il peut en garder beaucoup, et plus il est pur, plus l'énergie stockée est efficace.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    Most physical objects can contain magical energy,
    but some can store more in quantity, in quality, and over time.<br/>
    In general, the harder a material is, the longer it can store energy,
    the denser the more it can store, and the purer the more effective.<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Métaux</h3>
<h3 *ngIf="langue.l == 'en'">Metals</h3>

<p *ngIf="langue.l == 'fr'">
    Les métaux sont un cas particuliers dans le sens où toutes les caractéristiques sont amplifiées.
    (si bon alors très bon, si mauvais alors très mauvais)<br/>
    En majorité, les métaux sont sensibles à la magie, et forment de très bons médiums pour stocker de l'énergie magique, et de mauvaises armures pour les mages.<br/>
    La limite de la pureté reste pour autant, ce qui explique par exemple pourquoi l'acier n'est pas très utilisé en magie.<br/>
    En plus des propriétés classiques, les métaux suivent une autre règle :
    plus le métal est coloré (cuivre, or), plus il est mauvais, et plus il est blanc/argenté, plus il est bon.<br/>
    Liste (non exhaustive) des matériaux dans l'ordre décroissant de puissance (densité entre parenthèses) :<br/>
    mercure (13.5), argent (10.5), chrome (7.2), plomb (11.3), zinc (7.1), aluminium (2.7), fer (7.8), pierre (1.6), acier (7.8), or (19.3), cuivre (8.9), bronze (8.2).
</p>
<p *ngIf="langue.l == 'en'">
    Metals are special, in the sense that any property is amplified.
    (if good then very good, if bad then very bad)<br/>
    Generally, metals are sensitive to magic, and make good mediums to store magical energy, and bad armor to wear for mages.<br/>
    The limit of the purity of the material remains however, which explains for example why steel isn't very good for magic.<br/>
    In addition to the classic properties, metals follow another rule:
    the more colour the metal has (copper, gold), the worse, and the closer to white/silvery, the better.<br/>
    List (non exhaustive) of materials in decreasing order of power :<br/>
    mercury, silver, chrome, lead, zinc, aluminium, iron, stone, steel, gold, copper, bronze.
</p>
