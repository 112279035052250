<p *ngIf="langue.l == 'fr'">
    La vie à la citadelle de Mekair, la capitale du territoire lyune.
    <br/>
    <br/>
    <b>Naissance</b><br/>
    Récemment, un centre d'accouchement a été mis en place, avec des soigneurs
    (mages de purifications) qui aident les femmes à accoucher en sécurité
    et en bonne santé.
    <br/>
    <br/><b>Enfance</b><br/>
    Quelques maisons et familles se sont regroupées pour s'occuper des enfants avant qu'ils puissent aller à l'école.
    <br/>
    <br/><b>École</b><br/>
    À partir d'environ 5 ans, les enfants sont acceptés à l'école de la citadelle,
    où ils apprennent à lire, écrire, compter, le concept de magie, et d'autres sujets adaptés.
    <br/>
    L'école de la citadelle propose 3 cycles de 5 ans chaque, regroupant des apprentissages
    progressifs sur différents sujets, avec quelques options de personalisation de cursus.
    <br/>
    L'éducation est entièrement gratuite, en échange les élèves participent au maintient de la citadelle et de la ville.
    <br/>
    Par exemple, en premier cycle, ils aident à passer le balait, nettoyer les vitres ou passer des objets entre les étages.
    <br/>
    En deuxième cycle, ils peuvent aider des artisants et obtenir une première idée de leur prochain métier
     (assistant d'appoint, souvent fermiers qui ont besoins de petites mains).
    <br/>
    En troisième cycle, ils remplissent des missions pour la citadelle, comme protéger les fermes
    à l'extérieur des murs de bêtes sauvages, aller chercher des objets de valeur, ou d'autres demandes importantes.
    <br/>
    Le peu de guardes qu'il reste dans la ville sont réservés à sa protection stricte,
    et ne sont techniquement pas au service de la citadelle mais du conseil de la ville.
    <br/>
    <br/><b>Apprentis</b><br/>
    Après l'école, un ancient élève peut prendre un contrat d'apprentis pour un métier qui l'intéresse.
    <br/>
    Les contrats d'apprentis correspondent à un maître qui apprend son métier à un apprentis, mais sans payer ni que l'apprentis paye non plus.
    L'apprentis sert d'assistant au maître et ce dernier s'occupe du logement et de la nourriture en échange.
    <br/>
    Le contrat peut durer entre 1 et 3 ans selon les métiers et selon les cas.
    <br/>
    <br/><b>Métier</b><br/>
    Comme l'éducation est gratuite, il est imposé de tenir une liste exhaustive de toutes ses ventes, contrats et autres revenus.
    <br/>
    Une fois par mois, un groupe d'employés de la citadelle va récupérer les taxes des commerces de la capitale et des villages du territoire.
    <br/>
</p>

<p *ngIf="langue.l == 'en'">

</p>
