<p *ngIf="langue.l == 'fr'">
    Les Nekali, humains moitié chat, vivant dans les steppes au nord ouest.<br/>
    Physiquement, ils sont de taille moyenne, la peau hâlée, avec des oreilles, des yeux et une queue de chat.
    Un Nekali mesure autour de 1m50-1m70.<br/>
    Espérance de vie : 70 ans.<br/>
    Affinité avec la magie d'air (orange)<br/>
    La culture est centrée sur la famille et la nourriture, la liberté et le respect.<br/>
    [Thèmes/inspirations : Khajiit, miqote, méditerranéen, égyptien]<br/>
    <br/>
    Mentalité<br/>
    La vie est un cadeau, il faut en profiter.<br/>
    Le plus important dans un gouvernement n’est pas les règles qu’il impose,
    mais l’état d’esprit qu’il arrive à donner à son peuple.<br/>
    Si l’opportunité d’être gentil sans coût personnel se présente, on se doit de la prendre.
    Tous les conflits peuvent être résolus de façon calme avec de la nourriture.<br/>
    <br/>
    Background<br/>
    Les Nekali étant arrivés après la plupart des autres races,
    ils sont d’abord mis à l’écart, mais grâce à leur côté gentil et joueur,
    ils se rapprochent des Flyhn par la proximité des territoires, et des Lyunes, avec qui ils partagent la qualification de "nouvelle race".
    Les conflits à l’intérieur du territoire Nekali sont fréquents,
    mais pas représentatifs d’un manque d’unicité, et résolus en général avec des compromis autour d’un repas.
</p>

<p *ngIf="langue.l == 'en'">

</p>
