<h3 *ngIf="langue.l == 'fr'">Monnaie</h3>
<h3 *ngIf="langue.l == 'en'">Currency</h3>

<p *ngIf="langue.l == 'fr'">
    La monnaie utilisée est l'or, en général sous forme de pièces.
    Il existe plusieurs épaisseurs et tailles de pièces d'or, et évidemment des barres d'or pour du stockage en grande quantité.
    <br/>
    La pièce d'or a été normalisée en poids, et les différentes tailles de pièces d'or physiques représentent
    respectivement 0.5, 1, 2, 5 et 10 pièces d'or normalisées. L'appellation "pièce d'or" réfère à la pièce d'or normalisée.
    Certaines pièces d'or ont été moulées avec des sceaux spéciaux selon les régions et peuvent valoir plus que leur poids.
    La valeur dépend de la rareté, de la source et générallement aussi de la région.
    <br/>
    Le cuivre et l'argent ne sont pas vraiment utilisés pour la monnaie, mais pour des raisons opposées.
    <br/>
    Le cuivre ne vaut pas le coût de la fonte et du moulage en pièce pour être utile.
    <br/>
    L'argent à l'opposé, est rare et est aussi un des meilleurs matériaux pour stocker de l'énergie magique.
    Pour ordre de grandeur, une pièce d'argent vaut 1000 pièces d'or de la même taille.
</p>
<p *ngIf="langue.l == 'en'">

</p>

<h3 *ngIf="langue.l == 'fr'">Prix de la nourriture</h3>
<h3 *ngIf="langue.l == 'en'">Food prices</h3>

<p *ngIf="langue.l == 'fr'">
    De manière générale, un repas "normal" coûte entre 2 et 5 pièces d'or par personne,
    et rempli ses besoins nutritionnels pour la journée.<br/>
    Une baguette de pain coûte 1 po.<br/>
    Un repas à 2 po correspond à du pain et de la soupe.<br/>
    Un repas à 3 ou 4 po correspond à un ensemble de légumes, tubercules, champignons, etc.<br/>
    Un repas à 5 po peut contenir en plus de la viande, des fruits ou du fromage.<br/>
    Les rations de voyage coûtent 4 po par jour par personne.<br/>
</p>
<p *ngIf="langue.l == 'en'">

</p>

<h3 *ngIf="langue.l == 'fr'">Prix de l'hébergement</h3>
<h3 *ngIf="langue.l == 'en'">Housing prices</h3>

<p *ngIf="langue.l == 'fr'">
    De manière générale, une nuit à un hôtel/auberge coûte autour de 3 pièces d'or par chambre,
    et dans la plupart des établissements, la chambre est proposée en tandem avec un repas à un prix rabaissé.<br/>
    Le prix total peut varier de 5 à 8 po selon les qualités de l'accommodation.
</p>
<p *ngIf="langue.l == 'en'">

</p>
