import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blood-rules',
  templateUrl: './blood-rules.component.html',
  styleUrls: ['./blood-rules.component.css']
})
export class BloodRulesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
