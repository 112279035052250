import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-magic-uses-thunder',
  templateUrl: './magic-uses-thunder.component.html',
  styleUrls: ['./magic-uses-thunder.component.css']
})
export class MagicUsesThunderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
