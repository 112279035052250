<button class="button back-button">
    <a routerLink="/colours-of-magic/uses">
        <i class="fa-sharp fa-solid fa-caret-left"></i>
        Retour
    </a>
</button>

<div class="magic-pres">
    <div class="magic-title">
        Air
    </div>
    <div class="magic-stats">
        <div class="magic-purity">
            <div class="magic-active">pure</div>
            <div class="magic-inactive">combinée</div>
        </div>
        <div class="magic-tangibility">
            <div class="magic-active">Tangible</div>
            <div class="magic-inactive">intangible</div>
        </div>
        <div class="magic-det">
            <div>DET</div>
            <div>5</div>
        </div>
    </div>
</div>

<p>
    La magie d'air permet de sentir et controller l'air environant.<br>
    Quelques exemples d'utilisations : faire un courant d'air, amortir un impact, pousser quelque chose.<br>
    La magie d'air est très versatile et l'air est un élément omniprésent dans la plupart des situations.<br>
    La magie d'air donne aussi une capacité de détéction physique dans l'air.
    Une personne se concentrant peut évidemment sentir les déplacement de l'air causés par un ennemis qui marche, mais elle va aussi le sentir même sans se concentrer.<br>
</p>
