<p *ngIf="langue.l == 'fr'">
    Toutes les races ont leur propre langue, à part les Etrek qui utilisent le Lyune.<br>
    Certaines races ont plus l'habitude de parler plusieurs langues,
    comme les Nekali et les Sombres qui parlent presque tous le Lyune,
    et une majorité des Flyhn qui parlent le Sylvain.<br>
    Le Sombre est un dérivé du Sylvain, ce qui fait qu'entre eux
    les elfes sylvains et sombres peuvent globalement se comprendre,
    mais certaines nuances et détails ont du mal à passer.<br>
    Comme référence purement pour les relations, et non pour les origines/ressemblences
    (et même, ce n'est pas parfait):<br>
    Flyhn = grec (grec ancien évolué en grec nouveau, + alphabet différent des autres)<br>
    Sylvain = français (latin > vieux français > français actuel)<br>
    Sombre = québécois (dérivé du français, parlent aussi anglais)<br>
    Lyune = anglais (langue facile à apprendre, peu de natifs mais beaucoup d'utilisateurs, populaire pour l'ouverture commerciale)<br>
    Nekali = allemand (plus difficile à apprendre, parlent anglais)<br>
</p>
<p *ngIf="langue.l == 'en'">

</p>
