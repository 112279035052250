<nav class="band band3">
    <a routerLink="/lore/living/education" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Éducation</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Education</menu-button>
    </a>
    <a routerLink="/lore/living/economy" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Économie</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Economy</menu-button>
    </a>
    <a routerLink="/lore/living/professions" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Métiers</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Professions</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>
