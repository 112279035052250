<p *ngIf="langue.l == 'fr'">
    Magie runique = magie basée sur l'écriture de runes sur un support, nécessite d'avoir appris à écrire les runes, lire, grammaire.<br/>
    Effets libres à tout ce que tu peux écrire.<br/>
    Utilisation limitée par l'écriture et la connaissance.<br/>
    <br/>
    L'alphabet runique est unique et peut être appris comme toute autre langue, avec un tuteur, du temps et de l'entraînement.<br/>
    Cet alphabet ne contient pas de lettres à proprement parler, mais des symboles appelés runes.
    Chaque rune a un sens plus ou moins ouvert qui peut être traduit par un (ou plusieurs) mot(s).<br/>
    <br/>
    L'efficacité d'un sort runique va dépendre principalement du support d'écriture, et de l'énergie apportée.
</p>

<p *ngIf="langue.l == 'en'">

</p>