<p *ngIf="langue.l == 'fr'">
    Un mage d'esprit peut utiliser une manipulation pour forcer un esprit 
    à fonctionner plus vite et plus précisément pendant le temps de la concentration.<br/>
    Le tableau est identique à celui du ralentissement,
    où l'efficacité correspond à combien l'esprit est accéléré.
</p>
<p *ngIf="langue.l == 'en'">
    A mind mage can use a manipulation to force someone's mind
    to go faster and bettre for the duration of the concentration.<br/>
    The table is identical to the one for slowing,
    where the efficacity is how much the mind is sped by.
</p>
