<nav class="band band3">
    <a routerLink="/lore/magic/elemental" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Magie Élémentaire</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Elemental Magic</menu-button>
    </a>
    <a routerLink="/lore/magic/runic" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Magie Runique</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Runic Magique</menu-button>
    </a>
    <a routerLink="/lore/magic/materials" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Materiaux supports</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Support materials</menu-button>
    </a>
    <a routerLink="/lore/magic/colours" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Couleurs</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Colours</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>
