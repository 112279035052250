
<h3 *ngIf="langue.l == 'fr'">Types de concentrations</h3>
<h3 *ngIf="langue.l == 'en'">Types of concentrations</h3>

<p *ngIf="langue.l == 'fr'">
    Il existe 3 types majeurs de concentrations : une <b>concentration sur un sort actif</b> (nécessite la compétence en magie correspondante),
    une <b>concentration sur le contrôle d'un élément inerte</b> (disponible à tout personnage possédant au moins une magie quelconque),
    et les <b>autres concentrations</b> (par exemple pour préparer une attaque, ou bien pour garder une compétence active).
    Les deux premiers types sont des <b>concentrations magiques</b>, le dernier type correspond aux <b>concentrations physiques</b>.<br/>
    Chaque concentration (quel que soit son type) nécessite de dépenser 1 PA au début de chaque tour du personnage pour rester active.
    Si la concentration commence après le début du tour, alors le premier coût commencera au tour prochain.<br/>
    En plus du coût en PA, garder des concentrations actives est fatiguant et coûte des points d'énergie selon le niveau de chaque concentration.
    Le niveau d'une concentration est déterminé par la chose sur laquelle le personnage est concentré :<br/>
    - Pour les concentration de sorts actifs, le niveau de la concentration est le même que le niveau du sort.<br/>
    - Pour les concentrations de contrôle d'élément, le niveau de la concentration dépend de la taille et de la tangibilité de l'élément (cf tableau si dessous).<br/>
    - Pour les concentrations physiques, c'est niveau 1 par défaut (sauf si précisé dans la compétence).<br/>
    Il est possible de garder plusieurs concentrations à la fois, et leurs coûts se cumulent.<br/>
    <br/>
    Il existe des limites du nombre de concentrations <b>magiques</b> cumulables :<br/>
    Le <b>nombre total</b> des concentrations magiques ne peut pas dépasser la somme des points de spécialisation du personnage (toutes magies confondues).
    Le <b>niveau</b> d'une concentration magique d'un élément ne peut pas dépasser la valeur de spécialisation dans l'élément correspondant.<br/>
    Par exemple : Un mage ayant 3 de spécialisation en illusion, 1 en eau et 0 en ténèbre.
    Il peut avoir jusqu'à un total de 4 concentration, de niveaux inférieurs ou égaux à 3 en illusion, inférieurs ou égaux à 1 en eau, ou égal à 0 en ténèbres.<br/>
    <br/>
    Les <b>concentrations physiques</b> n'ont pas d'impact sur les concentrations magiques et peuvent être accumulées sans limite.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    <br/>
</p>


<p>
    <button class="button bullet-button" (click)="show_text_1()">
        <div *ngIf="show1">v</div>
        <div *ngIf="!show1">></div>
    </button>
    Contrôle sur un sort
</p>
<div class="divshow" *ngIf="show1">
    <div class="div-table" *ngIf="langue.l == 'fr'">
        <table>
            <tr>
                <th>Puissance du sort</th>
                <th>Niveau du sort</th>
                <th>Coût du sort</th>
                <th>Coût de la concentration</th>
            </tr>
            <tr>
                <td>0-4</td>
                <td>0</td>
                <td>0</td>
                <td>Être conscient</td>
            </tr>
            <tr>
                <td>5-9</td>
                <td>1</td>
                <td>1</td>
                <td>1 / minute (= 10 tours)</td>
            </tr>
            <tr>
                <td>10-14</td>
                <td>2</td>
                <td>2</td>
                <td>1 / 5 tours</td>
            </tr>
            <tr>
                <td>15-19</td>
                <td>3</td>
                <td>3</td>
                <td>1 / 2 tours</td>
            </tr>
            <tr>
                <td>20-24</td>
                <td>4</td>
                <td>4</td>
                <td>1 / tour</td>
            </tr>
            <tr>
                <td>25-29</td>
                <td>5</td>
                <td>5</td>
                <td>1 / tour</td>
            </tr>
        </table>
    </div>
    <div class="div-table" *ngIf="langue.l == 'en'">
        <table>
            <tr>
                <th>Puissance du sort</th>
                <th>Niveau du sort</th>
                <th>Coût du sort</th>
                <th>Coût de la concentration</th>
            </tr>
            <tr>
                <td>0-4</td>
                <td>0</td>
                <td>0</td>
                <td>Être conscient</td>
            </tr>
            <tr>
                <td>5-9</td>
                <td>1</td>
                <td>1</td>
                <td>1 / minute (= 10 tours)</td>
            </tr>
            <tr>
                <td>10-14</td>
                <td>2</td>
                <td>2</td>
                <td>1 / 5 tours</td>
            </tr>
            <tr>
                <td>15-19</td>
                <td>3</td>
                <td>3</td>
                <td>1 / 2 tours</td>
            </tr>
            <tr>
                <td>20-24</td>
                <td>4</td>
                <td>4</td>
                <td>1 / tour</td>
            </tr>
            <tr>
                <td>25-29</td>
                <td>5</td>
                <td>5</td>
                <td>1 / tour</td>
            </tr>
        </table>
    </div>
</div>

<p>
    <button class="button bullet-button" (click)="show_text_2()">
        <div *ngIf="show2">v</div>
        <div *ngIf="!show2">></div>
    </button>
    Contrôle sur un élément
</p>
<div class="divshow" *ngIf="show2">
    Niveaux de concentration sur le contrôle d'un élément selon sa taille et sa tangibilité
    <div class="div-table" *ngIf="langue.l == 'fr'">
        <table>
            <tr>
                <th>Taille de l'élément</th>
                <th>Tangible</th>
                <th>intangible</th>
            </tr>
            <tr>
                <td>1</td>
                <td>0</td>
                <td>0</td>
            </tr>
            <tr>
                <td>2</td>
                <td>0</td>
                <td>1</td>
            </tr>
            <tr>
                <td>3</td>
                <td>0</td>
                <td>1</td>
            </tr>
            <tr>
                <td>4</td>
                <td>1</td>
                <td>2</td>
            </tr>
            <tr>
                <td>5</td>
                <td>1</td>
                <td>2</td>
            </tr>
            <tr>
                <td>6</td>
                <td>2</td>
                <td>3</td>
            </tr>
            <tr>
                <td>7</td>
                <td>2</td>
                <td>3</td>
            </tr>
            <tr>
                <td>8</td>
                <td>3</td>
                <td>4</td>
            </tr>
            <tr>
                <td>9</td>
                <td>3</td>
                <td>4</td>
            </tr>
            <tr>
                <td>10+</td>
                <td>4</td>
                <td>5</td>
            </tr>
        </table>
    </div>
    <div class="div-table" *ngIf="langue.l == 'en'">
        <table>
            <tr>
                <th>Taille de l'élément</th>
                <th>Tangible</th>
                <th>intangible</th>
            </tr>
            <tr>
                <td>1</td>
                <td>0</td>
                <td>0</td>
            </tr>
            <tr>
                <td>2</td>
                <td>0</td>
                <td>1</td>
            </tr>
            <tr>
                <td>3</td>
                <td>0</td>
                <td>1</td>
            </tr>
            <tr>
                <td>4</td>
                <td>1</td>
                <td>2</td>
            </tr>
            <tr>
                <td>5</td>
                <td>1</td>
                <td>2</td>
            </tr>
            <tr>
                <td>6</td>
                <td>2</td>
                <td>3</td>
            </tr>
            <tr>
                <td>7</td>
                <td>2</td>
                <td>3</td>
            </tr>
            <tr>
                <td>8</td>
                <td>3</td>
                <td>4</td>
            </tr>
            <tr>
                <td>9</td>
                <td>3</td>
                <td>4</td>
            </tr>
            <tr>
                <td>10+</td>
                <td>4</td>
                <td>5</td>
            </tr>
        </table>
    </div>

</div>



<p>
    <button class="button bullet-button" (click)="show_text_3()">
        <div *ngIf="show3">v</div>
        <div *ngIf="!show3">></div>
    </button>
    Garder ses PR d'un tour à l'autre<br/>
    <span class="divshow" *ngIf="show3">
        cf <a routerLink="/system/combat" routerLinkActive="activebutton">préparer une action</a>
    </span>
</p>

<p>
    <button class="button bullet-button" (click)="show_text_4()">
        <div *ngIf="show4">v</div>
        <div *ngIf="!show4">></div>
    </button>
    Garder une compétence active<br/>
    <span class="divshow" *ngIf="show4">
        cf <a routerLink="/character/skills/attack" routerLinkActive="activebutton">compétences</a> comme Viser
    </span>

</p>
