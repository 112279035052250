<h3 *ngIf="langue.l == 'fr'">Création</h3>
<h3 *ngIf="langue.l == 'en'">Creation</h3>

<p *ngIf="langue.l == 'fr'">
    Pour manipuler un élément, un mage doit avoir de cet élément à disposition.
    Si il n'en existe pas à proximité, le mage peut en créer. Un élément créé est sous le contrôle de son créateur.<br/>
    La difficulté de créer un élément dépend du type de l'élément et de la taille invoquée.
    Un élément peut être tangible ou intangible (noté T et i respectivement).<br/>
    Les éléments tangibles sont plus difficiles à invoquer, coûtent plus d'énergie, mais demande une
    <a routerLink="/system/states" routerLinkActive="activebutton">concentration</a>
    plus faible pour les maintenir sous contrôle.<br/>
    À l'inverse, les éléments intangibles sont plus faciles à invoquer, coûtent moins d'énergie,
    mais requièrent une
    <a routerLink="/system/states" routerLinkActive="activebutton">concentration</a>
    plus élevée pour maintenir l'invocation active.<br/>
    De manière générale, <b>pour les magies intangibles, l'action de création peut être incluse avec celle de manipulation</b>
    en une action de 4PA (1PA pour création + 3PA pour manipulation). Le coût en énergie de la création s'ajoute à celui de la manipulation.<br/>
    <br/>
    À partir de 6 de sensitivité (inclus et plus), la création coûtent moins d'énergie.<br>
    L'invocation de magies tangibles ou combinées ajoute un coût en énergie (cf table ci-dessous). <br>
    Le coût en PA reste 1PA sous toutes condition. Toutes les indication de coût dans la table sont en énergie.
</p>
<p *ngIf="langue.l == 'en'">

</p>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Taille (choisie par la source)</th>
            <th>Coût de base si sensitivité 1-5</th>
            <th>Coût de base si sensitivité 6-9</th>
            <th>Coût additionnel pour magie tangible</th>
        </tr>
        <tr>
            <td>doigt - main (T1-2)</td>
            <td>0</td>
            <td>0</td>
            <td>+0</td>
        </tr>
        <tr>
            <td>bras - corps (T3-4)</td>
            <td>1</td>
            <td>0</td>
            <td>+1</td>
        </tr>
        <tr>
            <td>groupe (T5-6)</td>
            <td>2</td>
            <td>1</td>
            <td>+1</td>
        </tr>
        <tr>
            <td>plus grand (T7-8)</td>
            <td>3</td>
            <td>2</td>
            <td>+1</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Résultat (1d12 + sensitivité)</th>
            <th>Taille</th>
            <th>Coût de base</th>
            <th>Coût de magie tangible</th>
            <th>Coût de magie combinée</th>
        </tr>
        <tr>
            <td>1 - 5</td>
            <td>doigt - main</td>
            <td>0</td>
            <td>+1</td>
            <td>+1</td>
        </tr>
        <tr>
            <td>6 - 10</td>
            <td>bras - corps</td>
            <td>+1</td>
            <td>+1</td>
            <td>+1</td>
        </tr>
        <tr>
            <td>11+</td>
            <td>groupe</td>
            <td>+2</td>
            <td>+1</td>
            <td>+1</td>
        </tr>
    </table>
</div>

<h3 *ngIf="langue.l == 'fr'">Prise de contrôle</h3>
<h3 *ngIf="langue.l == 'en'">Taking control</h3>

<p *ngIf="langue.l == 'fr'">
    Un mage peut essayer de voler le contrôle qu'un autre mage possède sur un élément.<br/>
    Il est nécessaire que le mage possède la même magie que l'élément dont il veut prendre le contrôle.<br/>
    Les deux mages vont alors faire des jets de sensitivité opposés successifs.<br/>
    Le mage volant le contrôle doit obtenir 3 réussites pour voler le contrôle.
    Le mage se défendant doit en obtenir 2 pour le garder.<br/>
    Sur l'entièreté de leurs jets, les mages peuvent ajouter leur spécialisation dans cet élément une seule fois.<br/>
</p>

<p *ngIf="langue.l == 'en'">

</p>
