<h3 *ngIf="langue.l == 'fr'">Blessures</h3>
<h3 *ngIf="langue.l == 'en'">Wounds</h3>

<p *ngIf="langue.l == 'fr'">
    Quand une blessure est infligée, elle possède un type, une position, une taille et une intensité.<br/>
    Le type d'une blessure est casse, coupure, ou autre (brûlures, poison, ...).<br/>
    La position d'une blessure est l'endroit sur le corps où la blessure a été infligée.<br/>
    La taille et l'intensité de la blessure sont liées à l'attaque ayant causé la blessure. La plupart des blessures venant d'armes ont une taille de 2.<br/>
    <br/>
    Une blessure cause à sa victime des points de douleurs.
    L'intensité d'une blessure est liée à la douleur causée par cette blessure par :<br/>
    Douleur = Intensité + 1 -ou bien- Intensité = Douleur - 1.<br/>
    <b>Les brûlures ont une propriété spéciale qui leur donne Intensité = Douleur</b> donc 1 d'Intensité de plus qu'une blessure différente.
    La définition de soigner est de réduire l'intensité de la blessure, ce qui réduit aussi la douleur liée.<br/>
    Une blessure à 0 d'intensité existe encore<br/>
    Une blessure à -1 d'intensité est guérie et disparaît.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    When a wound is dealt, it possesses a type, a location, a size and an intensity.<br/>
    The type of a wound is in general either a cut or a break.<br/>
    The location of a wound is where on the body the wound was dealt.<br/>
    The size and intesity of the wound are linked to the attacked which cause the wound.<br/>
    <br/>
    A wound causes its victim pain points.<br/>
    The intensity of a wound is linked to the pain caused by this wound by :<br/>
    Pain = Intensity + 1 -or- Intensity = Pain - 1.<br/>
    The definition of healing is to reduce the intensity of the wound which also reduces the pain.<br/>
    A wound at 0 intensity still exists.<br/>
    A wound at -1 intensity is healed and disappears.<br/><br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Repos</h3>
<h3 *ngIf="langue.l == 'en'">Rest</h3>

<p *ngIf="langue.l == 'fr'">
    Un personnage regagne 2 points d'énergie par heure de repos (dans la limite de son maximum naturel).<br/>
    Un personnage étant blessé peut se reposer pour guerrir ses blessures. Il peut alors lancer <b>1d6</b> par blessure par heure de repos,
    et le résultat au dé déterminera si la blessure est soignée de 1 ou pas.<br/>
    Le calcul est effectué pour chaque blessure indépendemment, est chaque blessure commence à 1 point.
    Chaque bonne situation ajoute un point, et chaque mauvaise situation enlève un point (min 0).
    Les points décident du seuil qui accepte le soin : le nombre de point correspond au nombre de faces soignantes. Avoir plus de points donne plus d'opportunités.<br/>
    Par exemple : Si une blessure a 1 point, seul le 6 (au d6) permet de soigner. Si une blessure a 3 points, le 4, 5, et 6 permettent de soigner.<br/>
    Exemples de situations bénéfiques :<br/>
    - Si le personnage s'occupe de sa blessure, la blessure gagne un point. Une personne ne peut s'occuper que d'une blessure à la fois.
    Plusieurs personnages peuvent d'occuper de blessures différentes sur le même personnage blessé.<br/>
    - Si le repos est effectué dans un lieu de soin (hopital ou autre), la blessure gagne un point.<br/>
    Exemples de situations néfastes :<br/>
    - Si la blessure est infectée (en développement), la blessure perd un point.<br/>
    - Si le personnage se repose sans matelas ou lit, la blessure perd un point.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    A wounded character can rest to heal. They can roll <b>1d6</b> per wound per hour of rest.<br/>
    If the die lands on 6 then the corresponding wound is healed by 1.<br/>
    If the character tends to the wound, a 5 can also heal. A character can tend to only one wound at a time.
    Several characters can tend to different wounds on the same wounded character.<br/>
    A character also regains 2 points of energy per hour of rest (within the limits of its natural maximum).<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Dormir</h3>
<h3 *ngIf="langue.l == 'en'">Sleeping</h3>

<p *ngIf="langue.l == 'fr'">
    Une personne doit dormir un minimum de 6 heures par nuit pour ne pas recevoir de pénalité.<br/>
    Si un personnage dort moins de 6 heures, alors son énergie maximum diminue du nombre d'heures manquées.<br/>
    Si un personnage dort plus que 6 heures, alors son énergie maximum monte du nombre d'heures ajoutées (dans la limite de son maximum naturel).<br/>
    Un personnage ne peut pas monter son énergie au dessus de son maximum.<br/>
    Par exemple : Un personnage dort 2 heures pendant une nuit. Il perd donc (6-2 = 4) 4 d'énergie maximum et est donc à (10-4 = 6) 6 d'énergie max.
    Le nuit suivante, il dort 9 heures. Il récupère donc (9-6 = 3) 3 heures, et est maintenant à (6+3 = 9) 9 d'énergie maximum.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    A person has to sleep a minimum of 6 hours per night in order not to receive penalties.<br/>
    If a character sleeps less than 6 hours, then their maximum energy descreases by the number of lacking hours.<br/>
    If a character sleeps more than 6 hours, then their maximum energy increases by the number of added hours (within the bounds of their natural maximum).<br/>
    A character's energy cannot go over their maximum energy.<br/>
    For example : A character sleeps for 2 hours during a night. They lose (6-2 = 4) 4 maximum energy and are now at (10-4 = 6) 6 maximum energy instead of 10.
    The next night, they sleep 9 hours. They get back (9-6 = 3) 3 hours, and are now at (6+3 = 9) 9 maximum energy.<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Sorts</h3>
<h3 *ngIf="langue.l == 'en'">Spells</h3>

<p *ngIf="langue.l == 'fr'">
    Pour soigner une blessure avec un sort, celui-ci doit couvrir la blessure en Taille.<br/>
    La Distance correspond à la distance entre le soigneur et la personne blessée.<br/>
    Le soigneur lance <b>1d12 + Précision x2</b> et doit atteindre le seuil indiqué pour que le soin prenne effet.<br/>
    L'Efficacité détermine les points de soins disponibles pour soigner (cf tableau).<br/>
    La Taille du sort permet d'inclure plusieurs blessures dans les soins applicables.<br/>
    Par exemple : un personnage lance un sort de soin de purification avec ces stats E3-P4-T2-D1.<br/>
    - Distance 1 : le sort sera appliqué au contact.<br/>
    - Taille 2 : le sort peut englober une blessure de taille 2 comme une coupure d'une épée.<br/>
    - Précision 4 : le personnage lancera 1d12 + 8 pour déterminer si le sort prend effet ou non.
      Si le lancé atteint 11, alors il pourra soigner des blessures en profondeur, sinon le sort sera limité aux blessures de surface.<br/>
    - Efficacité 3 : le personnage aura 3 points de soin à répartir sur les blessures existantes et atteignables.<br/>
    <br/>
    Une Taille de 3 permet d'englober deux blessures différentes de taille 2. Une Taille 4 permet d'englober tout un corps humain (et en général toute une case).
    L'échelle de Taille suit le tableau des ordres de grandeur
    (rappel que les sorts de soins sont des manipulations, il faut donc créer l'élément correspondant dans le cas d'un élément non tangible).<br/>
    Dans le cas où le sort englobe plusieurs blessures, les points de soins doivent être répartis sur les différentes blessure (la répartission est au choix de la source)
    et chaque point de soin ne peut être utilisé que sur une blessure.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    Size and distance depending on wound and target.<br/>
    The healer rolls <b>1d12 + precision x2</b> and must reach the threshold for the heal to have effect.
</p>


<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Magie utilisée</th>
            <th>Eau</th>
            <th>Lumière</th>
            <th>Purification</th>
        </tr>
        <tr>
            <td>Quantité de soin</td>
            <td>efficacité -2</td>
            <td>efficacité -1</td>
            <td>efficacité</td>
        </tr>
        <tr>
            <td>Blessure de surface (coupures, brûlures, ...)</td>
            <td>DC9</td>
            <td>DC13</td>
            <td>DC9</td>
        </tr>
        <tr>
            <td>Blessure en profondeur (casses, perforations, ...)</td>
            <td>/</td>
            <td>DC15</td>
            <td>DC11</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Magic used</th>
            <th>Water</th>
            <th>Light</th>
            <th>Purification</th>
        </tr>
        <tr>
            <td>Heal amount</td>
            <td>efficacity -2</td>
            <td>efficacity -1</td>
            <td>efficacity</td>
        </tr>
        <tr>
            <td>Surface wound (cuts, burns, ...)</td>
            <td>DC9</td>
            <td>DC13</td>
            <td>DC9</td>
        </tr>
        <tr>
            <td>Deep wound (breaks, perforations, ...)</td>
            <td>/</td>
            <td>DC15</td>
            <td>DC11</td>
        </tr>
    </table>
</div>
