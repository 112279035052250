
<button class="skill-button {{skill_colour}}" (click)="click_skill(skill_name)">

  <!-- skill type (passif, action, other) -->
  <div>
    <span *ngIf="header == 'false'" class="skill-type">{{ skill_type }}</span>
    <span *ngIf="header == 'true'"><br></span>
  </div>
  <!-- skill name -->
  <div style="margin-top: 7px;">
    <span [ngClass]="{'header ': header == 'true', 'skill-name': header == 'false'}">
      {{ skill_name }}
    </span>
  </div>
  <!-- skill levels (in stars) -->
  <div style="margin-top: 7px;">
    <span *ngIf="header == 'false'">
      <span><i class="fa-solid fa-star"></i></span>
      <span *ngIf="this.skill_levels == '3' || this.skill_levels == '2'"><i class="fa-solid fa-star"></i></span>
      <span *ngIf="this.skill_levels == '3'"><i class="fa-solid fa-star"></i></span>
    </span>
    <span *ngIf="header == 'true'"><br></span>
  </div>
  <!-- skill descriptions (short & long) -->
  <span class="hovertext" *ngIf="!(opened_skill.name == skill_name)">{{ this.hovertext(skill_category, skill_depth, skill_name) }}</span>
  <span class="popuptext" *ngIf="(opened_skill.name == skill_name)">{{ this.details(skill_category, skill_depth, skill_name) }}</span>

</button>
