import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sercret-nekali',
  templateUrl: './sercret-nekali.component.html',
  styleUrls: ['./sercret-nekali.component.css']
})
export class SercretNekaliComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
