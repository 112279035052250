<p *ngIf="langue.l == 'fr'">
    Les Lyunes, humains de base, vivant dans la jungle du centre.<br/>
    Physiquement, un Lyune mesure autour de 1m60-1m90, une peau lisse, des oreilles rondes.<br/>
    Espérance de vie : 100 ans.<br/>
    Versatiles, pas de spécialité, pas d'affinité magique, pas de pénalité ou de limitation.<br/>
    La culture est centrée sur le respect et l'égalité, l'ouverture d'esprit et l'équité des opportunités.<br/>
    <br/>
    Mentalité<br/>
    Tous les êtres vivants méritent le même traitement.<br/>
    Une personne ne devrait pas être jugée ni restreinte par sa naissance.<br/>
    L'éducation et la santé de chaque individu sont essentiels pour la prospérité d'une société,
    c'est pour ça qu'il est interdit de restreindre leur accès (à travers un coût monétaire,
    une barrière raciale ou familiale, ...).<br/>
    <br/>
    Background<br/>
    Les Lyunes étant apparus dans la jungle centrale à peu près au même moment que les Nekali,
    ces deux races sont proches car "nouvelles races" en même temps.
    Beaucoup de Nekali parlent lyune, et quelques Lyunes parlent nekali.
    Ils ont de bonnes relations avec les Flyhn, mais assez hasardeuses avec les Elfes Sylvains et Sombres.
    Ils sont aussi très proches des Etreks.
    <br/>
    Les Lyunes sont orientés artisanat, constructions et technologies.
</p>

<p *ngIf="langue.l == 'en'">

</p>