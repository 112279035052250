<nav class="band band3">
    <a routerLink="/colours-of-magic/mind/reading" class="button" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Lire</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Reading</menu-button>
    </a>
    <a routerLink="/colours-of-magic/mind/slowing" class="button" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Ralentir</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Slowing</menu-button>
    </a>
    <a routerLink="/colours-of-magic/mind/boosting" class="button" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Booster</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Boosting</menu-button>
    </a>
    <a routerLink="/colours-of-magic/mind/hallucinating" class="button" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Halluciner</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Hallucinating</menu-button>
    </a>
    <a routerLink="/colours-of-magic/mind/suggesting" class="button" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Suggestion</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Suggestion</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>
