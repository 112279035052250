<br/>
<img class="image-schema" src="../../../assets/images/elements_new_transp.png"/>

<p style="margin-right: 40%;" *ngIf="langue.l == 'fr'">
    Les magies élémentaires sont séparées en magies pures et magies combinées. Il existe 6 magies pures et 12 magies combinées.
    Les magies combinées sont le résultat de la combinaison de 2 magies pures différentes non opposées.<br/>
    Pour combiner deux magies pures, il suffit qu'un humain (ou être sensé) possède ces deux magies pures.
    Il peut alors invoquer la magie combinée.<br/>
    Il est impossible de combiner deux magies pures opposées, ou de combiner une magies déjà combinée.<br/>
    Il n'a jamais été observé de personne possédant deux magies pures opposées.
    
</p>
<p style="margin-right: 40%;" *ngIf="langue.l == 'en'">

</p>