<p *ngIf="langue.l == 'fr'">
    DET : Damage Efficacity Threshold<br/>
    Minimum d'efficacité à atteindre pour que le sort puisse servir à faire des dégâts
</p>
<p *ngIf="langue.l == 'en'">
    DET : Damage Efficacity Threshold<br/>
    Minimum efficacity to reach in order for the spell to be able to deal damage.
</p>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Élement pur</th>
            <th>Tangibilité</th>
            <th>DET</th>
            <th>Effet simple</th>
            <th>Effet moyen</th>
            <th>Effet avancé</th>
            <th>Bonus</th>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/fire" routerLinkActive="activebutton">Feu</a></td>
            <td>i</td>
            <td>1</td>
            <td>Allumer une torche</td>
            <td>Trait de feu</td>
            <td>Chalumeau</td>
            <td>Détection magique</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/air" routerLinkActive="activebutton">Air</a></td>
            <td>T</td>
            <td>5</td>
            <td>Éteindre une torche</td>
            <td>Pousser qqn, amortir une chute</td>
            <td>Désarmer qqn</td>
            <td>Détection physique (dans l'air)</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/light" routerLinkActive="activebutton">Lumière</a></td>
            <td>i</td>
            <td>4</td>
            <td>Éclairer une pièce</td>
            <td>Éblouir qqn</td>
            <td>Barreaux</td>
            <td>Soins + détection magique légère</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/nature" routerLinkActive="activebutton">Nature</a></td>
            <td>T</td>
            <td>2</td>
            <td>Écarter des vignes</td>
            <td>Fouetter</td>
            <td>Restreindre qqn</td>
            <td>Discrétion magique</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/water" routerLinkActive="activebutton">Eau</a></td>
            <td>T</td>
            <td>3</td>
            <td>Transvaser</td>
            <td>Pousser qqn</td>
            <td>Couper</td>
            <td>Soins légers + détection physique dans l'eau</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/shadow" routerLinkActive="activebutton">Ténèbres</a></td>
            <td>i</td>
            <td>-</td>
            <td>Cacher qqch</td>
            <td>Bloquer une lumière</td>
            <td>Profondeur d'ombre</td>
            <td>Discrétion physique</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Pure element</th>
            <th>Tangibility</th>
            <th>Simple effect</th>
            <th>Medium effect</th>
            <th>Advanced effect</th>
            <th>Bonus</th>
        </tr>
        <tr>
            <td>Fire</td>
            <td>i</td>
            <td>Light a torch</td>
            <td>Firebolt</td>
            <td>Flamethrower</td>
            <td>Magic detection</td>
        </tr>
        <tr>
            <td>Air</td>
            <td>T</td>
            <td>Put out a torch</td>
            <td>Push someone, cushion a fall</td>
            <td>Disarm someone</td>
            <td>Physical detection (in air)</td>
        </tr>
        <tr>
            <td>Light</td>
            <td>i</td>
            <td>Light a room</td>
            <td>Blind someone</td>
            <td>Light bars</td>
            <td>Healing + small magic detection</td>
        </tr>
        <tr>
            <td>Nature</td>
            <td>T</td>
            <td>Move vines</td>
            <td>Whip</td>
            <td>Restrain someone</td>
            <td>Magic discretion</td>
        </tr>
        <tr>
            <td>Water</td>
            <td>T</td>
            <td>Move water</td>
            <td>Push someone</td>
            <td>Cut</td>
            <td>Small healing + physical detection in water</td>
        </tr>
        <tr>
            <td>Shadows</td>
            <td>i</td>
            <td>Hide someone</td>
            <td>Block a light source</td>
            <td>Shadow depth</td>
            <td>Physical discretion</td>
        </tr>
    </table>
</div>

<br/>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Élement combiné</th>
            <th>Tangibilité</th>
            <th>DET</th>
            <th>Effet simple</th>
            <th>Effet moyen</th>
            <th>Effet avancé</th>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/explosion" routerLinkActive="activebutton">Explosion</a></td>
            <td>i</td>
            <td>1</td>
            <td>Exploser du verre</td>
            <td>Exploser du bois ou de la pierre</td>
            <td>Exploser du metal</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/thunder" routerLinkActive="activebutton">Tonnerre</a></td>
            <td>i</td>
            <td>1</td>
            <td>Allumer une torche</td>
            <td>Electriser quelqu'un</td>
            <td>Electrocuter quelqu'un</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/purification" routerLinkActive="activebutton">Purification*</a></td>
            <td>i</td>
            <td>4</td>
            <td>Assainir de l'eau</td>
            <td>Soigner une blessure ou une maladie</td>
            <td>Supprimer le contrôle d'âme</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/growth" routerLinkActive="activebutton">Croissance</a></td>
            <td>i</td>
            <td>3</td>
            <td>Faire pousser des plantes</td>
            <td>Agrandir le sol pour faire un pont ou un mur</td>
            <td>Agrandir une personne</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/animal" routerLinkActive="activebutton">Animaux</a></td>
            <td>T</td>
            <td>3</td>
            <td>Communication totale et contrôle basique des animaux</td>
            <td>Gagner les particularités d'animaux</td>
            <td>Appel et contrôle total d'animaux</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/earth" routerLinkActive="activebutton">Terre</a></td>
            <td>T</td>
            <td>3</td>
            <td>Écarter de la terre ou des cailloux</td>
            <td>Lancer des rochers</td>
            <td>Gagner la résistance de la pierre</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/ice" routerLinkActive="activebutton">Glace</a></td>
            <td>T</td>
            <td>2</td>
            <td>Transformer de l'eau en glace</td>
            <td>Envoyer des pics de glace, faire une plateforme en glace</td>
            <td>Faire une tombe/prison de glace</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/firewater" routerLinkActive="activebutton">Eau de vie</a></td>
            <td>T</td>
            <td>1</td>
            <td>Transformer de l'eau en eau de vie</td>
            <td>Lance-flamme</td>
            <td>Fondre du métal</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/illusion" routerLinkActive="activebutton">Illusion*</a></td>
            <td>i</td>
            <td>-</td>
            <td>Changer la couleur de quelque chose</td>
            <td>Appliquer une illusion sur de l'eau</td>
            <td>Se rendre invisible</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/mind" routerLinkActive="activebutton">Esprit*</a></td>
            <td>T</td>
            <td>-</td>
            <td>Savoir si une personne est consciente,<br/>communiquer avec des gens volontaires</td>
            <td>Lire les pensées de surface,<br/>communiquer avec des gens non volontaires</td>
            <td>Lire, modifier et supprimer des souvenirs</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/tentacles" routerLinkActive="activebutton">Tentacules</a></td>
            <td>i</td>
            <td>2</td>
            <td>Faire un tentacule</td>
            <td>Fouetter avec un tentacule</td>
            <td>Porter du lourd avec un tentacule</td>
        </tr>
        <tr>
            <td><a routerLink="/colours-of-magic/soul" routerLinkActive="activebutton">Âme*</a></td>
            <td>T</td>
            <td>-</td>
            <td>Ressentir les émotions de surface</td>
            <td>Imposer des émotions</td>
            <td>Modifier la douleur et l'énergie</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Combined element</th>
            <th>Tangibility</th>
            <th>Simple effect</th>
            <th>Moderate effect</th>
            <th>Advanced effect</th>
        </tr>
        <tr>
            <td>Explosion</td>
            <td>i</td>
            <td>Explode glass</td>
            <td>Explode wood or stone</td>
            <td>Explode metal</td>
        </tr>
        <tr>
            <td>Thunder</td>
            <td>i</td>
            <td>Light a torch</td>
            <td>Electrify someone</td>
            <td>Electrocute someone</td>
        </tr>
        <tr>
            <td>Purification</td>
            <td>i</td>
            <td>Sanitise water</td>
            <td>Heal a wound or a sickness</td>
            <td>Remove soul control</td>
        </tr>
        <tr>
            <td>Growth</td>
            <td>i</td>
            <td>Make plants grow</td>
            <td>Grow the ground to make a bridge or a wall</td>
            <td>Grow someone</td>
        </tr>
        <tr>
            <td>Animals</td>
            <td>T</td>
            <td>Basic control over animals</td>
            <td>Gain special animal abilities</td>
            <td>Full control and can call animals</td>
        </tr>
        <tr>
            <td>Earth</td>
            <td>T</td>
            <td>Push dirt or pebbles</td>
            <td>Throw rocks</td>
            <td>Gain rock's resistance</td>
        </tr>
        <tr>
            <td>Ice</td>
            <td>T</td>
            <td>Transform water into ice</td>
            <td>Throw ice spikes, make an ice platform</td>
            <td>Make an ice tomb/prison.</td>
        </tr>
        <tr>
            <td>Firewater</td>
            <td>T</td>
            <td>Transform water into firewater</td>
            <td>Flamethrower</td>
            <td>Melt metal</td>
        </tr>
        <tr>
            <td>Illusion</td>
            <td>i</td>
            <td>Change the colour of something</td>
            <td>Apply an illusion on water</td>
            <td>Become invisible</td>
        </tr>
        <tr>
            <td>Mind</td>
            <td>T</td>
            <td>Know if someone is conscious, communicate with willing people</td>
            <td>Read surface thoughts, communicate with unwilling people</td>
            <td>Read, modify and delete memories</td>
        </tr>
        <tr>
            <td>Tentacles</td>
            <td>i</td>
            <td>Create a tentacle</td>
            <td>Whip with a tentacle</td>
            <td>Lift with a tentacle</td>
        </tr>
        <tr>
            <td>Soul</td>
            <td>T</td>
            <td>Feel surface emotions</td>
            <td>Impose emotions</td>
            <td>Modify pain and energy</td>
        </tr>
    </table>
</div>

<p *ngIf="langue.l == 'fr'">
    * = magie non physique, à opposer à la sensitivité à la place de la résistance.
</p>
<p *ngIf="langue.l == 'en'">
    * = non physical magic, use sensitivity instead of resistance for the target.
</p>


<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Élements</th>
            <th class="fire">Feu</th>
            <th class="air">Air</th>
            <th class="light">Lumière</th>
            <th class="nature">Nature</th>
            <th class="water">Eau</th>
            <th class="shadow">Ténèbres</th>
        </tr>
        <tr>
            <th class="fire">Feu</th>
            <td class="fire"><a routerLink="/colours-of-magic/fire" routerLinkActive="activebutton">Feu</a></td>
            <td class="explosion"><a routerLink="/colours-of-magic/explosion" routerLinkActive="activebutton">Explosion</a></td>
            <td class="purification"><a routerLink="/colours-of-magic/purification" routerLinkActive="activebutton">Purification</a></td>
            <td class="null">/</td>
            <td class="firewater"><a routerLink="/colours-of-magic/firewater" routerLinkActive="activebutton">Eau de vie</a></td>
            <td class="soul"><a routerLink="/colours-of-magic/soul" routerLinkActive="activebutton">Âme</a></td>
        </tr>
        <tr>
            <th class="air">Air</th>
            <td class="explosion"><a routerLink="/colours-of-magic/explosion" routerLinkActive="activebutton">Explosion</a></td>
            <td class="air"><a routerLink="/colours-of-magic/air" routerLinkActive="activebutton">Air</a></td>
            <td class="thunder"><a routerLink="/colours-of-magic/thunder" routerLinkActive="activebutton">Tonnerre</a></td>
            <td class="animals"><a routerLink="/colours-of-magic/animal" routerLinkActive="activebutton">Animaux</a></td>
            <td class="null">/</td>
            <td class="tentacles"><a routerLink="/colours-of-magic/tentacles" routerLinkActive="activebutton">Tentacules</a></td>
        </tr>
        <tr>
            <th class="light">Lumière</th>
            <td class="purification"><a routerLink="/colours-of-magic/purification" routerLinkActive="activebutton">Purification</a></td>
            <td class="thunder"><a routerLink="/colours-of-magic/thunder" routerLinkActive="activebutton">Tonnerre</a></td>
            <td class="light"><a routerLink="/colours-of-magic/light" routerLinkActive="activebutton">Lumière</a></td>
            <td class="growth"><a routerLink="/colours-of-magic/growth" routerLinkActive="activebutton">Croissance</a></td>
            <td class="ice"><a routerLink="/colours-of-magic/ice" routerLinkActive="activebutton">Glace</a></td>
            <td class="null">/</td>
        </tr>
        <tr>
            <th class="nature">Nature</th>
            <td class="null">/</td>
            <td class="animals"><a routerLink="/colours-of-magic/animal" routerLinkActive="activebutton">Animaux</a></td>
            <td class="growth"><a routerLink="/colours-of-magic/growth" routerLinkActive="activebutton">Croissance</a></td>
            <td class="nature"><a routerLink="/colours-of-magic/nature" routerLinkActive="activebutton">Nature</a></td>
            <td class="earth"><a routerLink="/colours-of-magic/earth" routerLinkActive="activebutton">Terre</a></td>
            <td class="mind"><a routerLink="/colours-of-magic/mind" routerLinkActive="activebutton">Esprit</a></td>
        </tr>
        <tr>
            <th class="water">Eau</th>
            <td class="firewater"><a routerLink="/colours-of-magic/firewater" routerLinkActive="activebutton">Eau de vie</a></td>
            <td class="null">/</td>
            <td class="ice"><a routerLink="/colours-of-magic/ice" routerLinkActive="activebutton">Glace</a></td>
            <td class="earth"><a routerLink="/colours-of-magic/earth" routerLinkActive="activebutton">Terre</a></td>
            <td class="water"><a routerLink="/colours-of-magic/water" routerLinkActive="activebutton">Eau</a></td>
            <td class="illusion"><a routerLink="/colours-of-magic/illusion" routerLinkActive="activebutton">Illusion</a></td>
        </tr>
        <tr>
            <th class="shadow">Ténèbres</th>
            <td class="soul"><a routerLink="/colours-of-magic/soul" routerLinkActive="activebutton">Âme</a></td>
            <td class="tentacles"><a routerLink="/colours-of-magic/tentacles" routerLinkActive="activebutton">Tentacules</a></td>
            <td class="null">/</td>
            <td class="mind"><a routerLink="/colours-of-magic/mind" routerLinkActive="activebutton">Esprit</a></td>
            <td class="illusion"><a routerLink="/colours-of-magic/illusion" routerLinkActive="activebutton">Illusion</a></td>
            <td class="shadow"><a routerLink="/colours-of-magic/shadow" routerLinkActive="activebutton">Ténèbres</a></td>
        </tr>
    </table>
</div>


<h2>Hexagramme avec bouttons</h2>

<div id="hexacle_box">

    <!-- FEU --> <a style= "margin: 17px 197px" routerLink="/colours-of-magic/fire"
        class="hexacle_button_node"></a>

    <!-- AIR --> <a style= "margin: 77px 57px" routerLink="/colours-of-magic/air"
        class="hexacle_button_node"></a>

    <!-- LUMIÈRE --> <a style= "margin: 247px 48px" routerLink="/colours-of-magic/light"
        class="hexacle_button_node"></a>

    <!-- NATURE --> <a style= "margin: 324px 199px" routerLink="/colours-of-magic/nature"
        class="hexacle_button_node"></a>

    <!-- EAU --> <a style= "margin: 248px 343px" routerLink="/colours-of-magic/water"
        class="hexacle_button_node"></a>

    <!-- TÉNÈBRES --> <a style= "margin: 83px 343px" routerLink="/colours-of-magic/shadow"
        class="hexacle_button_node"></a>

    <!-- style : "padding: [height/2]px [width/2]px; margin: [pos_y]px [pos_x]px"-->

    <!-- EXPLOSION --> <a style= "padding: 9px 30px; margin: 44px 103px" routerLink="/colours-of-magic/explosion"
        class="hexacle_button_edge"></a>

    <!-- PURIFICATION --> <a style= "padding: 10px 38px; margin: 148px 160px" routerLink="/colours-of-magic/purification"
        class="hexacle_button_edge"></a>

    <!-- EAU DE VIE --> <a style= "padding: 9px 33px; margin: 168px 231px" routerLink="/colours-of-magic/firewater"
        class="hexacle_button_edge"></a>

    <!-- AME --> <a style= "padding: 7px 14px; margin: 53px 304px" routerLink="/colours-of-magic/soul"
    class="hexacle_button_edge"></a>

    <!-- TONNERRE --> <a style= "padding: 8px 29px; margin: 175px 17px" routerLink="/colours-of-magic/thunder"
        class="hexacle_button_edge"></a>

    <!-- ANIMAUX --> <a style= "padding: 8px 29px; margin: 214px 160px" routerLink="/colours-of-magic/animal"
        class="hexacle_button_edge"></a>

    <!-- TENTACULES --> <a style= "padding: 9px 34px; margin: 115px 190px" routerLink="/colours-of-magic/tentacles"
    class="hexacle_button_edge"></a>

    <!-- CROISSANCE #Macron2022 --> <a style= "padding: 8px 37px; margin: 318px 96px" routerLink="/colours-of-magic/growth"
        class="hexacle_button_edge"></a>

    <!-- GLACE --> <a style= "padding: 10px 21px; margin: 247px 210px" routerLink="/colours-of-magic/ice"
        class="hexacle_button_edge"></a>

    <!-- TERRE --> <a style= "padding: 8px 17px; margin: 321px 293px" routerLink="/colours-of-magic/earth"
    class="hexacle_button_edge"></a>

    <!-- ESPRIT --> <a style= "padding: 8px 20px; margin: 221px 254px" routerLink="/colours-of-magic/mind"
        class="hexacle_button_edge"></a>

    <!-- ILLUSION --> <a style= "padding: 9px 23px; margin: 179px 376px" routerLink="/colours-of-magic/illusion"
        class="hexacle_button_edge"></a>



</div>
