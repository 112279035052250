<p *ngIf="langue.l == 'fr'">
    Les Etreks sont très peu nombreux, et ont des difficultés pour augmenter leurs nombres.<br/>
    Les Lyunes développent une société qui s'étend et commence à empiéter sur les territoires avoisinants, surtout le territoire sylvain.<br/>
    Les sylvains leur disent d'arrêter, ils arrêtent pas => gueguerre<br/>
    Les elfes sombres sautent sur l'occasion de se venger et s'allient avec les Lyunes.<br/>
    Les Nekali ont plus de liens avec les Lyunes et les Sombres et les rejoinent donc.<br/>
    Les Flyhns cette fois décident de participer et s'allient aux Sylvains, même si toute la population n'est pas d'accord et un bon nombre fuient pour rejoindre le camps Lyune.<br/>
    Les Etreks restent hors du conflit, ne pouvant pas se permettre de perdre des gens.<br/>
</p>
<p *ngIf="langue.l == 'en'">

</p>