
<h2 *ngIf="langue.l == 'fr'">Concentré</h2>
<h2 *ngIf="langue.l == 'en'">Concentrated</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : préparer une action, garder un sort actif<br/>
	<b>Effets</b> : Coûte 1 PA par tour par concentration pour rester concentré.<br/>
	<b>Effets spéciaux</b> : > Si la concentration vient d'un sort de niveau 1 ou plus, alors il faut continuellement dépenser de l'énergie pour garder le sort actif.<br/>
    - niveau 1 : 1 énergie / minute<br/>
    - niveau 2 : 1 énergie / 5 tours<br/>
    - niveau 3 : 1 énergie / 2 tours<br/>
    - niveau 4+ : 1 énergie / tour<br/>
    <br/>
    Pas besoin de concentration pour un sort de niveau 0.<br/>
    <br/>
    Le nombre de concentration par niveau est limité par 1 + la spécialisation dans la magie correspondante.<br/>
    Exemple : Un mage d'eau et de glace ayant des spécialisations 1/1 peut avoir 2 concentrations de niveaux 0.
    Si il veut une troisième concentration de niveau 0, cette troisième concentration sera comptée comme de niveau 1.<br/>
    Si ce mage se spécialise dans la magie de glace, il aura alors 0/2 et donc peut avoir 1 concentration d'eau et 3 de glace de niveau 0.
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : preparing an action, keeping a spell active<br/>
	<b>Effects</b> : character is limited in their actions (see combat).<br/>
    Costs 1 AP per turn per concentration to stay concentrated.<br/>
	<b>Special effects</b> : > If the concentration comes from a spell of level 1 or more, then energy must be continually spent to keep the spell active.<br/>
    - level 1 : 1 energy / minute<br/>
    - level 2 : 1 energy / 5 turns<br/>
    - level 3 : 1 energy / 2 turns<br/>
    - level 4+ : 1 energy / turn<br/>
    <br/>
    No concentration for spells of level 0.<br/>
    <br/>
    Le nombre de concentration par niveau est limité par 1 + la spécialisation dans la magie correspondante.<br/>
    Exemple : Un mage d'eau et de glace ayant des spécialisations 1/1 peut avoir 2 concentrations de niveaux 0.
    Si il veut une troisième concentration de niveau 0, cette troisième concentration sera compté comme de niveau 1.<br/>
    Si ce mage se spécialise dans la magie de glace, il aura alors 0/2 et donc peut avoir 1 concentration d'eau et 3 de glace de niveau 0.
</p>

<h2 *ngIf="langue.l == 'fr'">Blessé</h2>
<h2 *ngIf="langue.l == 'en'">Hurt</h2>

<p *ngIf="langue.l == 'fr'">
    <b>Source</b> : Douleur à 4 ou plus<br/>
    <b>Effets</b> : Au début de son tour, lance dé de valeur correspondante.
    (cf <a routerLink="/system/gauges" routerLinkActive="activebutton">jauges</a>)<br/>
    Si le résultat du dé + la somme des intensités des casses + les somme des intensités des blessures gênantes est <b>strictement supérieur</b> à 2 + la résistance,
    alors les points d'action et de réaction sont divisés par 2 pour ce tour (arrondi bas, min 0)<br/>
    Douleur : 1  2  3 <span style="color:rgb(240, 240, 0)"> 4  5 </span><span style="color:rgb(230, 149, 0)"> 6  7 </span><span style="color:orangered"> 8  9</span><br/>
    Dé : 0, <span style="color:rgb(240, 240, 0)">d4</span>, <span style="color:rgb(230, 149, 0)">d6</span>, <span style="color:orangered">d8</span><br/>
    Si le jet est raté, le personnage peut décider de perde un point d'énergie pour le réussir. Dans ce cas, il commence son tour avec tous ses PA et PR.
</p>
<p *ngIf="langue.l == 'en'">
    <b>Source</b> : Pain at 4 or more<br/>
    <b>Effects</b> : At the start of the turn, roll the corresponding die
    (see <a routerLink="/system/gauges" routerLinkActive="activebutton">gauges</a>)<br/>
    If the result of the die + the sum of the intensity of all breaks is strictly higher than 2 + the character's resistance,
    then their AP and RP are divided by 2 for this turn (rounded down, minimum 0)<br/>
    Pain : 1  2  3 <span style="color:rgb(240, 240, 0)"> 4  5 </span><span style="color:rgb(230, 149, 0)"> 6  7 </span><span style="color:orangered"> 8  9</span><br/>
    Die : 0, <span style="color:rgb(240, 240, 0)">d4</span>, <span style="color:rgb(230, 149, 0)">d6</span>, <span style="color:orangered">d8</span><br/>
    If the roll fails, the character can decide to lose one energy point point to instead succeed. In this case, they start their turn with full AP and RP.
</p>

<h2 *ngIf="langue.l == 'fr'">Fatigué</h2>
<h2 *ngIf="langue.l == 'en'">Tired</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : Energie à 6 ou moins<br/>
	<b>Effets</b> : Malus aux jets selon la <a routerLink="/system/gauges" routerLinkActive="activebutton">fatigue</a>.<br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : Energy at 6 or less<br/>
	<b>Effects</b> : Penalty to rolls depending on the <a routerLink="/system/gauges" routerLinkActive="activebutton">fatigue</a>.<br/>
</p>

<h2 *ngIf="langue.l == 'fr'">Inconscient</h2>
<h2 *ngIf="langue.l == 'en'">Unconscious</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : Energie à 0 ou Douleur à 10 (ou autre)<br/>
	<b>Effets</b> : tombe par terre, perd connaissance, perd concentrations.<br/>
	<b>Effets spéciaux</b> : > si Douleur à 10, dure 1 minute ; > si Energie à 0, dure 1 heure<br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : Energy at 0 or Pain at 10 (or other)<br/>
	<b>Effects</b> : fall on the ground, lose consciousness, lose all concentrations.<br/>
	<b>Special effects</b> : > If Pain at 10, lasts 1 minute ; > If Energy at 0, lasts 1 hour<br/>
</p>

<h2 *ngIf="langue.l == 'fr'">En méditation</h2>
<h2 *ngIf="langue.l == 'en'">Meditating</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : Méditer (débloqué par compétence en magie)<br/>
    <b>Effets</b> : La personne en train de méditer doit être assise ou alongée, dans une optique de repos.<br/>
    Elle devient incapable d'attaquer ou de lancer de sorts sans sortir de méditation,
    mais elle peut encore parler, utiliser un objet ou d'autres intéractions.<br/>
    <b>Effets spéciaux</b> : <br>
    > Après 5 minutes de méditation et si la méditation continue, alors la personne peut lancer des sorts,
    et le coût en énergie des sorts et des concentrations est ignoré (= 0).<br/>
    > Après 10 minutes totales de méditation, le personnage peut interrompre la méditation et gagner 1 point d'énergie temporaire.<br/>
    > Si la méditation dure 1 heure, récupère 1 point d'énergie + 2 points d'énergie temporaire.<br/>
    > Si combiné avec un sort en concentration, le lanceur du sort est de plus en plus limité suivant la puissance du sort.<br/>
    - niveau 2 : immobilisé (peut seulement parler)<br/>
    - niveau 3 : + aveugle<br/>
    - niveau 4+ : + sourd<br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : Meditation (unlocked by magic skill)<br/>
    <b>Effects</b> : The meditating character must be sitting or laying down, as if resting.<br/>
    They become unable to attack or cast spells without leaving meditation, but they can still speak, use objects or other interactions.<br/>
    <b>Special effects</b> : > If the meditation lasts 1 hour, the character regains 1 point of energy and gains 2 points of temporary energy.<br/>
    > After 5 minutes of meditation and if the meditation continues, then the character can cast spells again,
    and all costs in energy of spells and concentrations are ignored..<br/>
    > If combined with a concentrating spell, the caster is more and more limited depending on the level of the spell:<br/>
    - level 2 : immobilised (can only talk)<br/>
    - level 3 : + blind<br/>
    - level 4+ : + deaf<br/>
</p>

<h2 *ngIf="langue.l == 'fr'">Distrait</h2>
<h2 *ngIf="langue.l == 'en'">Distracted</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : Attaqué par quelqu'un avec la compétence distraction -ou- échec critique à l'attaque<br/>
    <b>Effets</b> : -2 à l'esquive jusqu'au début de son prochain tour<br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : Attacked by someone with the distraction skill -or- critical failed attack<br/>
    <b>Effects</b> : -2 to evasion until the start of their next turn<br/>
</p>

<h2 *ngIf="langue.l == 'fr'">Occupé</h2>
<h2 *ngIf="langue.l == 'en'">Occupied</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : Un ennemi est occupé si un allié est au contact de cet ennemi et l'un a attaqué l'autre au tour dernier.<br/>
    <b>Effets</b> : Les personnages avec certaines compétences gagnent des avantages contre une personne occupée.<br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : Facing an enemy and having hit them<br/>
    <b>Effects</b> : <br/>
</p>


<h2 *ngIf="langue.l == 'fr'">À genous</h2>
<h2 *ngIf="langue.l == 'en'">On your knees</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : Décider de mettre un genou à terre, ou être tombé et en train de se relever<br/>
    <b>Effets</b> : -1 aux attaques de contact ; -1 à l'esquive ; aucun malus aux attaques à distance, ne peut que marcher (et pas courrir)<br/>
    <b>Effets spéciaux</b> : gratuit de passer de debout à à genous, coûte 1 PA (ou 1 PR) de passer de à genous à debout<br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : Deciding to put a knee on the ground, or having fallen and getting back up<br/>
    <b>Effects</b> : -1 to all melee attacks ; -1 to evade ; nothing to ranged attacks, can only walk (not run)<br/>
    <b>Special effects</b> : free to go from standing to on your knees, costs 1 AP (or 1 RP) to go from on your knees to standing<br/>
</p>


<h2 *ngIf="langue.l == 'fr'">Allongé</h2>
<h2 *ngIf="langue.l == 'en'">Lying down</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : S'allonger volontairement ou être tombé<br/>
    <b>Effets</b> : -3 aux attaques de contact ; -3 à l'esquive au contact ; +2 à l'esquive d'attaque à distance ; +2 aux attaques à distance<br/>
    <b>Effets spéciaux</b> : gratuit de passer de à genous à allongé, coûte 1 PA (ou 1 PR) de passer de allongé à à genous<br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : Willingly lying down or having fallen<br/>
    <b>Effects</b> : -3 to all melee attacks ; -3 to evade melee attacks ; +2 to evade ranged attacks ; -2 to ranged attacks<br/>
    <b>Special effects</b> : free to go from on your knees to lying down, costs 1 AP (or 1 RP) to go from lying down to on your knees<br/>
</p>

<!--
<h2 *ngIf="langue.l == 'fr'">Occupé</h2>
<h2 *ngIf="langue.l == 'en'">Occupied</h2>

<p *ngIf="langue.l == 'fr'">
	<b>Source</b> : <br/>
    <b>Effets</b> : <br/>
    <b>Effets spéciaux</b> : <br/>
</p>
<p *ngIf="langue.l == 'en'">
	<b>Source</b> : <br/>
    <b>Effects</b> : <br/>
    <b>Special effects</b> : <br/>
</p>
-->
