<p *ngIf="langue.l == 'fr'">
    Les Flyhns, humains moitié oiseau, vivent dans les montagnes au sud ouest.<br/>
    Ils ont des ailes, mais planner est fatigant et voler est impossible.<br/>
    Physiquement, ils ont souvent les cheveux blonds ou clairs, la peau pâle ou bronzée,
    taille moyenne ou petite, quelques plumes aux oreilles, et une paire d'ailes dans le dos.
    Un Flyhn mesure autour de 1m40-1m60. Contrairement aux autres races, les femmes flyhn sont typiquement plus grandes que les hommes flyhn.<br/>
    Espérance de vie : 200 ans.<br/>
    Affinité avec la magie de lumière (jaune)<br/>
    La culture est centrée sur la communauté et l'honneur, la hiérarchie et la générosité.<br/>
    [Thèmes/inspirations : valkyrie, Nordic, grec]<br/>
    <br/>
    Mentalité<br/>
    Toute personne faisant partie d’un groupe doit se battre pour la survie de ce groupe.<br/>
    Pour cela, il faut obéir aux ordres donnés sans remise en question.<br/>
    L’idéal serait d’unifier le monde sous un seul gouvernement bienfaisant et ordonné,
    et tout être sensé devrait vouloir tendre vers cet idéal.<br/>
    En attendant, beaucoup de personnes sont perdues et il est du devoir de chacun d’aider son prochain.<br/>
    <br/>
    Background<br/>
    Les Flyhns ont une façon de vivre bien moins agressive que celle des Elfes Sylvains,
    et voient leur tempérament belliqueux comme un rabaissement de l’être sensé, et une fragilisation du groupe.
    Le peuple flyhn a l’air heureux grâce à leur gouvernement fort, et les Flyhns fiers de le suivre.
    Pendant la première guerre, les Flyhns sont restés à l’écart car les Sylvains leur avaient demandé (par fierté) de ne pas se mêler des affaires des elfes.
    Donc quand la deuxième guerre est déclarée, les Flyhns ne peuvent pas rester sans rien faire, mais les réactions diffèrent selon les personnes.
    C’est ainsi que la race est scindée en deux.
</p>

<p *ngIf="langue.l == 'en'">

</p>
