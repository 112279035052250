import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sercret-dark-elves',
  templateUrl: './sercret-dark-elves.component.html',
  styleUrls: ['./sercret-dark-elves.component.css']
})
export class SercretDarkElvesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
