<br/><br/>
<div class="div-table">
  <table class="skill-table" >
    <tr *ngFor="let depth of this.depths">
      <td *ngFor="let skill of this.skills[depth].name_list">
        <span *ngIf="skill !== 'nothing'">
          <app-skill-button
            [skill_name]="skill"
            [skill_category]="this.skills[depth].skill_list[skill].category"
            [skill_depth]="depth"
            [header]="this.skills[depth].skill_list[skill].header"
            [skill_type]="this.skills[depth].skill_list[skill].type"
            [skill_levels]="this.skills[depth].skill_list[skill].levels"
            [skill_colour]="this.skills[depth].skill_list[skill].colour"
          >
          </app-skill-button>
        </span>
      </td>
    </tr>
  </table>
</div>

<br/><br/>

<div class="div-skills">
    <img style="vertical-align: middle;" src="../../../assets/images/skill_trees/skill_tree_attack.png"/>
    <button class="button-skills xtop left">
        <span class="hovertext">
            Être capable d'utiliser des armes de distance en combat.<br/><br/>
            Pour chaque attaque, le personnage attaquant doit cibler un membre spécifique de sa victime.<br/>
            Selon le membre, l'attaque peut subir un malus au toucher*.
            Si l'attaque touche, la victime souffre des pénalités selon le membre touché et les dégâts*.
        </span>
    </button>
    <button class="button-skills xtop right">
        <span class="hovertext">
            Être capable d'utiliser des armes de mêlée en combat.<br/><br/>
            Pour chaque attaque, le personnage attaquant doit cibler un membre spécifique de sa victime.<br/>
            Selon le membre, l'attaque peut subir un malus au toucher*.
            Si l'attaque touche, la victime souffre des pénalités selon le membre touché et les dégâts*.<br/>
        </span>
    </button>
    <button class="button-skills top xleft">
        <span class="hovertext">
            Viser - Utiliser des PA pour augmenter ses chances de toucher.<br/><br/>
            Consommer entre 1, 2 ou 3 PA (une fois par tour) pour obtenir +1, +2 ou +3 (respectivement) au toucher pour la prochaine attaque à distance.<br/>
            Demande concentration.<br/>
            Niveau 2 : Ne demande plus concentration.<br/>
        </span>
    </button>
    <button class="button-skills top left">
        <span class="hovertext">
            Distraction - Attaquer un ennemi le distrait.<br/><br/>
            Attaquer un ennemi, si l'attaque touche, qu'elle fasse de dégât ou pas, alors l'ennemi est distrait jusqu'à son prochain tour.<br/>
            Un ennemi distrait a -2 à l'esquive.<br/>
        </span>
    </button>
    <button class="button-skills top center">
        <span class="hovertext">
            Analyse - Analyser les mouvements d'un ennemi pour le toucher plus facilement.<br/><br/>
            Consomme 1 PA (une fois par tour) pour analyser une créature
            et gagner +1 au toucher pour toutes les prochaines attaques sur cette créature. Cummulable.<br/>
            Si le +3 au toucher est atteint, un +1 au dégât est ajouté. Un +5 au toucher donne un +2 aux dégâts, etc.<br/>
            Niveau 2 : Commence tout de suite à +2 au toucher.<br/>
            Niveau 3 : Commence tout de suite à +3 au toucher et +1 aux dégâts.<br/>
        </span>
    </button>
    <button class="button-skills top right">
        <span class="hovertext">
            Ambidextrie - Être capable de manipuler une arme dans chaque main en combat.<br/><br/>
            La deuxième arme doit être une arme légère.<br/>
            Lors d'une attaque avec son arme principale, consomme 1 PA additionnel ou 1 point d'énergie pour faire une attaque additionnelle avec sa deuxième arme.<br/>
            Niveau 2 : Une fois par tour, peut effectuer une attaque avec sa deuxième arme gratuitement après une attaque avec son arme principale.<br/>
        </span>
    </button>
    <button class="button-skills top xright">
        <span class="hovertext">
            Parade - Réagir pour essayer de parer une attaque.<br/><br/>
            Consomme 1 PR pour gagner +1 à l'esquive.<br/>
            Activable quand l'attaque est annoncée mais avant que le toucher soit lancé.<br/>
            Niveau 2 : Gagne +2 à l'esquive en parant (à la place du +1).<br/>
        </span>
    </button>
    <button class="button-skills middle xleft">
        <span class="hovertext">
            Crit étendu - Avoir plus de chance de crit.<br/><br/>
            Si le résultat du jet d'attaque est supérieur au résultat d'esquive de 5 ou plus, l'attaque est un crit (ignore résistance naturelle).<br/>
        </span>
    </button>
    <button class="button-skills middle left">
        <span class="hovertext">
            Diversion - Gagner des avantages contre les ennemis occupés.<br/><br/>
            Ne provoque plus de réaction de la part d'un ennemi occupé.<br/>
            De plus, le personnage gagne un +1 au toucher contre les ennemis occupés.<br/>
            Niveau 2 : Gagne +2 au toucher contre les ennemis occupés (à la place du +1).<br/>
        </span>
    </button>
    <button class="button-skills middle center">
        <span class="hovertext">
            Présence et Inspiration - Sa présence influe sur les ennemis.<br/><br/>
            Bonus - Au début de chaque combat, faire un choix entre Présence Imposante et Présence Discrète
            (il faut être visible des ennemis pour utiliser Présence Imposante).<br/>
            Action - Une fois par tour, peut consommer 1 PA pour donner Inspiration à un allié (+1 au toucher pour le prochain tour).<br/>
            Niveau 2 : Donner Inspiration ne coûte plus de PA.<br/>
        </span>
    </button>
    <button class="button-skills middle right">
        <span class="hovertext">
            Surge - Gagner des PA.<br/><br/>
            Une fois par tour, peut consommer 2 points d'énergie pour gagner 4 PA pour un tour.<br/>
            Les PA gagnés ainsi ne peuvent être cumulés, ni gardés pour un tour suivant.<br/>
            Niveau 2 : Une fois par jour, peut activer Surge sans coûter d'énergie.<br/>
        </span>
    </button>
    <button class="button-skills middle xright">
        <span class="hovertext">
            Contre-attaque - Contre-attaquer sur une esquive réussie.<br/><br/>
            Effectuer une attaque en réaction (prix indiqué dans la table système > combat). Activable seulement après avoir esquivé une attaque.<br/>
            Consomme 1 PR de moins si l'attaque a été parée.
        </span>
    </button>
    <button class="button-skills bot xleft">
        <span class="hovertext bot-line">
            Double-flèche - Pouvoir tirer deux flèches à la fois.<br/><br/>
            Effectuer une attaque normale avec un arc,
            mais consomme deux flèches et doit cibler 2 ennemis différents.<br/>
            Les deux ennemis doivent être dans un angle de 90°.<br/>
            Un seul lancé de toucher avec un -2, et deux lancés pour les dégâts.<br/>
            Niveau 2 : Le toucher a un -1 et non un -2.
        </span>
    </button>
    <button class="button-skills bot left">
        <span class="hovertext bot-line">
            Angle mort - Plus d'avantages contre les ennemis occupés.<br/><br/>
            Gagne un +1 aux dégâts contre les ennemis occupés.<br/>
            En plus, si l'ennemis n'a pas vu l'attaquant au tour dernier, l'attaque gagne un +3 au toucher.
        </span>
    </button>
    <button class="button-skills bot center">
        <span class="hovertext bot-line">
            Vengeance - Bonus contre les ennemis selon la Présence.<br/><br/>
            Gagne le niveau d'analyse de +3/+1 contre les ennemis qui ont attaqué
            la Présence ou quelqu'un d'autre, pour respectivement la Présence Imposante et Discrète.
        </span>
    </button>
    <button class="button-skills bot right">
        <span class="hovertext bot-line">
            Extra damage - Plus de dégâts et continuation.<br/><br/>
            Toutes les attaques avec des armes gagnent un +1 aux dégâts.<br/>
            En plus, si l'attaque fait 5 ou plus de dégâts, l'ennemis trébuche
            et permet une continuation**.<br/>
        </span>
    </button>
    <button class="button-skills bot xright">
        <span class="hovertext bot-line">
            Charge - Attaque chargée.<br/><br/>
            Consomme les PA d'une attaque selon l'arme, mais permet de se déplacer
            jusqu'à 3 cases gratuitement avant l'attaque, tant que la direction du déplacement
            correspond à la direction de l'attaquant vers la cible.<br/>
            Si 2 cases ou plus ont été traversées, et que l'attaque touche, l'ennemis trébuche
            et permet une continuation**.<br/>
            Niveau 2 : Si 2 cases ou plus ont été traversées,
            gagne un +1 au toucher pour la première attaque, et +2 au toucher à la continuation.<br/>
        </span>
    </button>
</div>

<!-- <p *ngIf="langue.l == 'fr'">
    * : cf <a routerLink="/system/attacking/targeting" routerLinkActive="activebutton">cibler</a><br/>
    ** : cf <a routerLink="/system/attacking/hitting" routerLinkActive="activebutton">continuation</a>
</p>
<p *ngIf="langue.l == 'en'">
    * : see <a routerLink="/system/attacking/targeting" routerLinkActive="activebutton">targeting</a><br/>
    ** : see <a routerLink="/system/attacking/hitting" routerLinkActive="activebutton">continuation</a>
</p> -->
