import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-magic-uses-earth',
  templateUrl: './magic-uses-earth.component.html',
  styleUrls: ['./magic-uses-earth.component.css']
})
export class MagicUsesEarthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
