<p *ngIf="langue.l == 'fr'">
    Magie élémentaire = magie naturelle, innée, faisable sans entraînement ni apprentissage, vient de l'intérieur de soi pour manipuler les éléments à l'extérieur.<br/>
    L’apprentissage aide à se concentrer, mais l’affinité reste un facteur important<br/>
    Effets limités à des éléments.<br/>
    Utilisation limitée par l'énergie et l'entraînement.<br/>
    <br/>
    Le principe de la magie en Destalyr est la création et la manipulation des éléments.
    La personne qui décide de créer et/ou manipuler s’appelle la source de cette magie.<br/>
    Il y a 6 éléments purs (lumière, air, feu, ténèbres, eau et nature) et 12 éléments combinés
    (cf <a routerLink="/lore/magic/colours" routerLinkActive="activebutton">couleurs</a>).
    Les éléments purs sont référés comme les 6 couleurs de la magie.<br/>
    <br/>
    La pratique de la magie consiste en une concentration profonde, un appel aux éléments, puis une interaction avec eux,
    qui peut résulter en une création ou une manipulation, l'ensemble étant appelé invocation.
    La différence entre les deux est que la manipulation correspond à un mouvement ou changement de l’élément déjà présent,
    alors que la création correspond à l'apparition de l’élément en question.
    Une situation classique d'invocation de magie se résume en une création (rapide)
    puis une manipulation de l’élément créé (plus ou moins longue).<br/>
    Selon l’élément, les créations et manipulations peuvent être plus ou moins difficiles
    (création facile pour le feu, mais difficile pour la nature ;
    manipulation facile pour l’eau, difficile pour la lumière).<br/>
    En moyenne, la manipulation reste plus facile que la création.<br/>
    <br/>
    Le contrôle de sa magie (créée ou manipulée) est une partie importante dans l’utilisation
    puisqu’une magie incontrôlée peut se retourner contre sa source et son entourage, et devient très dangereuse.
    Mais tant que le contrôle est maintenu, la source peut manipuler l’élément à souhait.<br/>
    L’énergie utilisée pour créer et manipuler les éléments vient de la source,
    c’est pour ça que de grandes invocations peuvent drainer une personne de son énergie,
    ce qui peut pousser à la perte de conscience.
    Aucune utilisation de magie résultant en la mort de sa source a été observée (jusque là).<br/>
    <br/>
    La magie est en général apprise aux enfants à l’âge d’environ 10 ans,
    et l’apprentissage dure plusieurs années (cf <a routerLink="/lore/living/education" routerLinkActive="activebutton">éducation</a>).
    Il n’est pas impossible de l’apprendre seul, ou avant 10 ans, mais reste des cas rares.<br/>
    Tous les êtres sensés (par opposition aux être sauvages (= animaux et plantes)) ont la capacité d’apprendre la magie.
    Certains y sont moins sensibles et peuvent avoir plus de mal, d’autres peuvent juste ne pas vouloir,
    et d’autres encore peuvent ne pas en connaître l’existence (dans certaines régions reculées).
    Mais la majorité des capacités sont apprises avec expérience et entraînements.<br/>
    <br/>
    Certains êtres sauvages peuvent manipuler certaines magies,
    mais un animal ne manipulera qu’une seule magie (pure ou combinée),
    alors qu’un être sensé peut en manipuler jusqu’à 3 (2 pures + 1 combinée).<br/>
    <br/>
    Le contrôle des éléments permet plus que la simple création ou manipulation des éléments en question,
    chaque élément pur a sa spécialité (cf <a routerLink="/colours-of-magic/uses" routerLinkActive="activebutton">magie utilisations</a>).
    <br/>
    L'utilisation de magie est détectable proportionellement à la puissance du sort lancé, et peut laisser des traces magiques.<br/>
    Certaines magies peuvent être connotées à cause de leurs utilités,
    comme la magie d'âme (feu + ténèbres) qui est interdite dans la plupart des sociétés,
    puisque son utilisation est une violation des droits individuels.<br/>
    Par opposition, la magie de la purification (feu + lumière) est très bien vue, car majoritairement bénéfique.
</p>

<p *ngIf="langue.l == 'en'">

</p>
