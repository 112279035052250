<p *ngIf="langue.l == 'fr'">
    Pour faire une magie de groupe, il faut :<br/>
    - Un mage qui sera la source, celui qui lancera effectivement le sort.<br/>
    - Un ou plusieurs autres participants volontaires partageant la magie du sort voulu.<br/>
    Le déroulé : <br/>
    - Chaque mage lance ses propres dés<br/>
    - Tous les dés de la source sont gardés.<br/>
    - Les participants peuvent donner à la source un nombre de leurs dés allant jusqu'à leur spécialisation dans la magie indiquée.<br/>
    - L'ensemble des dés disponibles à la source sont distribués sur les caractéristiques et le sort est lancé.<br/>
    - La source dépense en énergie le prix de son sort avec ses dés seulement.<br/>
    - Chaque participant perd en énergie le nombre de dés qu'ils ont donnés.<br/>

    <br/>
    Exemple :<br/>
    Mage A, B et C ont tous la magie d'eau.<br/>
    A et B ont une idée super de faire la plus grande tour d'eau possible.
    C trouve ça stupide. Il n'est pas volontaire. A et B arrivent à convaincre C, et C devient volontaire (même si avec une volonté minime).<br/>
    Les sensitivités magiques de A, B et C sont (respectivement) 3, 4 et 5. Leur spécialité est de 1 (pour les 3).<br/>
    Ils lancent leurs dés :<br/>
    A : 4-1-2-2 (3d4+1d2)<br/>
    B : 2-4-4-1 (4d4)<br/>
    C : 1-3-3-1-3 (5d4)<br/>
    Ils décident que A lancera le sort (4+1+2+2 = 9). B donne un 4 et C donne un 3.<br/>
    Il en font : E2-P2-T8-D4.<br/>
    B et C perdent 1 d'énergie chacun, et A perd 1 d'énergie aussi (pour le sort de puissance 9).<br/>

</p>

<p *ngIf="langue.l == 'en'">

</p>
