<h3 *ngIf="langue.l == 'fr'">Croyances globales</h3>
<h3 *ngIf="langue.l == 'en'">Global beliefs</h3>

<p *ngIf="langue.l == 'fr'">
    Parmis les être vivants, les humains sont des êtres sensés, par opposition aux animaux et plantes qui sont des êtres sauvages.<br/>
    Tout être sensé est composé de trois parts : le corps, l'esprit et l'âme.
    Le corps est la partie physique de l'être, pendant que l'esprit et l'âme sont immatériels. <br/>
    L'esprit gouverne les pensées, le raisonnement et les souvenirs. L'âme est responsable des émotions et des sensations.
    Ensemble, l'esprit et l'âme forment l'essence.<br/>
    Quand un être sensé meurt, ses trois parts sont séparées. Le corps est abandonné au monde physique, et l'esprit et l'âme èrent l'espace.
    Si ces deux arrivent à se retrouver et à se réunir, alors l'essence de l'être est reformée et peut retrouver les essences des Six dans une nouvelle vie.<br/>
    Pour la croyance dans les Six essences, la reformation de l'essence d'un être est nécessaire pour rejoindre les Six originaux au paradis.<br/>
    Pour la croyance dans les éléments éternels, l'emphase est plus sur le corps et son retour aux éléments primaires.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    Among living things, humans are sensible beings, in opposition to animals and plants, which are savage beings.<br/>
    Every sensible being is made up of three parts: the body, the mind and the soul.
    The body is the physical part of the being, while the mind and soul are immaterial.<br/>
    The mind governs the thoughts, the reasoning and the memories. The soul is responsible for the emotions and the sensations.
    Together, the mind and the soul form the spirit.<br/>
    When a sensible being dies, their three parts are separated. The body is abandoned to the physical world, and the mind and soul wander in space.
    If these two manage to find each other and rejoin, then the spirit of the being is reformed and can join the Six into the afterlife<br/>
    For the belief in the six spirits, the reunion of the spirit is necessary to join the six in paradise.<br/>
    For the belief in the eternal elements, the emphasis is more on the body and its return to the primary elements.<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Religions</h3>
<h3 *ngIf="langue.l == 'en'">Religions</h3>

<p *ngIf="langue.l == 'fr'">
    Une religion répandue est la croyance dans les essences des éléments.
    Elle porte sur l’origine des éléments    et pose que 6 êtres, à l’origine des temps,
    se sont transformés en éléments pour créer les 6 éléments purs. Maintenant,
    les êtres sensés peuvent utiliser la magie grâce à eux, et que lorsqu’un élément est invoqué,
    l'essence de cet élément est présente dans l’invocation, et est donc gardée en vie à travers l’utilisation de la magie.
    Quand un être veut contrôler un élément, il communique (consciemment ou pas) avec l’essence correspondante,
    et l’essence lui ouvre la possibilité si elle considère l’être comme méritant.
    Cela explique la facilité de certains envers des magies et des difficultés dans d’autres.<br/>
    Les six êtres originaux sont souvent représentés par des silhouettes sans visages de la couleur de leur élément.
    Leur sacrifice a permis le développement de la vie, et est donc vu comme le don suprême.<br/>
    <br/>
    Certaines personnes doutent de cette histoire puisque l’origine des six êtres est inconnue,
    et si chaque être maintenant vit des éléments, comment est-ce que des êtres auraient pu vivre avant la création des éléments ?
    Eux pensent plutôt que les éléments ont toujours existé, et que les êtres sensés naissent en empruntant un peu de tous les éléments
    quand ils naissent, mais plus de certains, avec lesquels ils auront plus de lien (et donc une manipulation plus facile) durant leur vie.
    Quand ils meurent, ils rendent ces éléments empruntés. La facilité avec certains éléments est donc décidée à la naissance.<br/>
    <br/>
    Enfin, un groupe plus restreint pensent que les Six êtres originaux sont les êtres les plus purs et parfaits,
    et que les magies combinées sont des hérésies qui ternissent l’image et la puissance des Six originaux.
    Ils ont décidé de n’apprendre qu’une ou deux magies pures mais pas la combinée,
    et veulent honorer les six en retirant toutes les magies combinées du monde, et les gens les utilisant.<br/>
</p>

<p *ngIf="langue.l == 'en'">
    The main religion is the belief in the spirits of the elements.
    It is based on the origin of the elements and sets that 6 beings, at the beginning of time,
    transformed into the elements to create the 6 pure elements. Now,
    sensible beings can use magic thanks to them, and when an element is invoked,
    the spirit of this element is present in the invocation, and is therefore kept alive through the use of elemental magic.
    When a being wants to control an element, it communicates (consciously or not) with the corresponding spirit,
    and the spirit allows the magic if it considers the being worthy.
    This explains how some people have more affinity with some magics, and less with others.<br/>
    The six originals are often represented as faceless silhouettes of the colour of their element.
    Their sacrifice has allowed for life to develop, and is thus seen as the ultimate gift.<br/>
    <br/>
    Certain people doubt this story since the origin of the six beings is unknown,
    and if every being now is made from the elements, how did these six beings come into existance before their creation ?
    These people believe that the elements have always been there, and that sensible beings borrow some of each element
    when they are born, some more than others, with which they will have a deeper link (and manipulate more easily) during their life.
    When they die, they give back these borrowed elements. The different affinities are therefore decided at birth.<br/>
    <br/>
    Finally, a smaller group believe that the original six are the most pure and perfect beings,
    and that combined magics are heresy which tarnish the image and power of the six.
    They have decided to only learn one or two pure magic and not the combined,
    and want to honor the six by removing all combined magics from the world.<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Superstitions</h3>
<h3 *ngIf="langue.l == 'en'">Superstitions</h3>

<p *ngIf="langue.l == 'fr'">
    Pour lister les éléments purs, la plupart des gens évitent de mettre feu et ténèbres côte à côte à cause des préjugés sur la magie d'âme.
</p>
<p *ngIf="langue.l == 'en'">
    When listing the pure elements, most people will avoid putting fire and shadows next to each other because of the predjudice against soul magic.
</p>
