<nav class="band band2">
    <a routerLink="/character/creation" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Création</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Creation</menu-button>
    </a>
    <a routerLink="/character/magic" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Magie</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Magic</menu-button>
    </a>
    <a routerLink="/character/stats" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Stats</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Stats</menu-button>
    </a>
    <a routerLink="/character/skills" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Compétences</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Skills</menu-button>
    </a>
    <a routerLink="/character/knowledge" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Connaissances</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Knowledge</menu-button>
    </a>
    <a routerLink="/character/dying" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Mourir</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Dying</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>