<button class="button back-button">
    <a routerLink="/colours-of-magic/uses">
        <i class="fa-sharp fa-solid fa-caret-left"></i>
        Retour
    </a>
</button>

<div class="magic-pres">
    <div class="magic-title">
        Feu
    </div>
    <div class="magic-stats">
        <div class="magic-purity">
            <div class="magic-active">pure</div>
            <div class="magic-inactive">combinée</div>
        </div>
        <div class="magic-tangibility">
            <div class="magic-inactive">Tangible</div>
            <div class="magic-active">intangible</div>
        </div>
        <div class="magic-det">
            <div>DET</div>
            <div>1</div>
        </div>
    </div>
</div>

<p>
    La magie de feu est une des plus intuitives. Elle permet de créer et contrôler du feu.<br>
    Quelques exemples sont : lancer un trait de feu, créer une boule de feu, faire un chalumeau, ou tout simplement allumer une torche.<br>
    Le feu sous contrôle est différent du feu naturel sur plusieurs aspects :<br>
    D'abord, il n'a pas besoin de combustible pour exister. L'énergie de la source est suffisante pour le garder actif.<br>
    Ensuite, tant que la source garde le contrôle, elle peut décider sur quoi le feu s'attache et peut protéger un objet ou une personne selon ses envies.<br>
    De la même façon, la source décide de ce que le feu va toucher et brûler. Le feu naturel peut être poussé par une bourasque tandis que le feu magique va (au moins essayer de) résister.<br>
    Enfin, le feu sous contrôle est de manière générale beaucoup plus dangereux que le feu naturel à cause des blessures plus intenses qu'il peut provoquer.<br>
    En plus du simple contrôl du feu, la magie de feu octroie une capacité à détecter (ou sentir) la magie environante.<br>
    La magie de feu permet de sentir les magies actives ainsi que les traces de magies, mais ne permet pas de savoir de quelle magie il s'agit la plupart du temps.<br>
</p>
