<p *ngIf="langue.l == 'fr'">
    Les connaissances d'une créature dépendent de ses expériences et représentent l'avancée du personnage
     et l'impact que l'environnement a pu avoir sur lui pendant sa vie.
    <br/>
    Un personnage peut apprendre de nouvelles connaissances seul ou avec un tuteur (selon les connaissances).
    <br/>
    Cet aspect du personnage est relativement libre et il est encouragé de réfléchir à son personnage et à son histoire
     pour trouver les connaissances les plus appropriées.
    Le joueur est responsable de la justification in-game du choix de son personnage et de la logique dans le déroulé de la campagne.
    Le joueur est aussi fortement encouragé à faire des recherches sur le sujet correspondant et de garder des sources
     qui pourront lui être demandées par le MJ pendant le jeu.
    Un joueur peut inventer des connaissances ou bien s'inspirer ou se baser sur une des connaissances présentées ici
     et la modifier pour la rendre plus adaptée au personnage (à voir avec le MJ pour l'équilibrage).
    <br/>
    Les éléments <b>en gras</b> indiquent les parties requises et non négotiables des compétences correspondantes.
    <br/>
    <br/>
    Quelques exemples de connaissances :<br/>
    <br/>
    - 1er exemple de types de connaissances (vision) : l'utilisation d'une <b>magie pure</b> pendant l'apprentissage d'un métier ou d'un domaine de recherche
      peut mener à un approfondissement de la capacité "vision élémentaire" ainsi que des connaissances dans le métier correspondant.<br/>
    vision de feu + cuisine / forge / etc.<br/>
    vision d'air + météorologie / sonore / etc.<br/>
    vision de lumière + optique / astronomie / etc.<br/>
    vision de nature + botanique / élevage / etc.<br/>
    vision d'eau + sailing / alchemie / etc.<br/>
    vision de ténèbres + se cacher / voir à travers les illusions / etc.<br/>
    <br/>
    - 2e exemple de types de connaissances (spec) : l'utilisation intense de sa <b>magie majeure ou combinée</b> et l'intérêt dans une sous catégorie de cette magie
      peuvent mener à un point bonus de Spécialisation dans cette magie ainsi que des connaissances dans la sous catégorie correspondante.<br/>
    feu +> détection magique<br/>
    air +> détection physique<br/>
    lumière +> soins<br/>
    nature +> discrétion magique<br/>
    eau +> soins<br/>
    ténèbre +> discrétion physique<br/>
    <br/>
    explosion +> faire chauffer l'air et sécher les choses / explosions petites et controllées<br/>
    purification +> amplifier les dégâts / soigner les blessures<br/>
    eau de vie +> brûlures en profondeur<br/>
    âme +> perturbation magique / détection émotions<br/>
    tonnerre +> interaction avec les muscles / tonnerre du ciel<br/>
    animaux +> appel / self-buffs<br/>
    tentacules +> structure / armes<br/>
    croissance +> buff / construction<br/>
    glace +> formes spécifiques / construction<br/>
    terre +> construction / buff<br/>
    esprit +> buff / debuff / detection mémoires<br/>
    illusion +> invisibilité / apparence modifiée<br/>
    <br/>
    - 3e exemple de types de connaissances (stat) : l'apprentissage d'un métier <b>avec un tuteur</b> utilisant une stat en particulier
      peut mener à un point bonus dans cette stat ainsi que des connaissances dans le métier.<br/>
    force + forgeron<br/>
    résistance + fermier<br/>
    dextérité + tanneur<br/>
    agilité + livreur<br/>
    perception + professeur<br/>
    éloquence + marchand<br/>
    <br/>
    - 4e exemple de types de connaissances (skill) : un entraînement intense, rigoureux, et orienté
      peut mener à une modification ou une amélioration d'une <b>compétences préalablement aquise</b>.<br/>
    armes dex > accès aux skills de force avec la dex<br/>
    armures lourdes > l'agi capée à +1 qu'indiqué par l'armure.<br/>
    réaction magie > gagne un autre trigger pour magie ou mouvement<br/>
    conjuration > permet de conjurer 2 petits companions au lieu d'un moyen.<br/>
    <br/>
    - 5e exemple de types de connaissances (culture) : l'apprentissage d'une culture d'une race, ainsi que sa langue, la géographie du territoire, et la politique actuelle.
      Cet apprentissage doit se faire <b>avec une personne de cette race ou en immersion dans le territoire</b> sur une période adéquate.
      Le personnage gagne aussi un bonus passif de +2 à tout jet d'éloquence envers une personne de la race ou de la culture correspondante.
</p>
<p *ngIf="langue.l == 'en'">

</p>
