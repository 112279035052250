<br/><br/>
<div class="div-table">
  <table class="skill-table" >
    <tr *ngFor="let depth of this.depths">
      <td *ngFor="let skill of this.skills[depth].name_list">
        <span *ngIf="skill !== 'nothing'">
          <app-skill-button
            [skill_name]="skill"
            [skill_category]="this.skills[depth].skill_list[skill].category"
            [skill_depth]="depth"
            [header]="this.skills[depth].skill_list[skill].header"
            [skill_type]="this.skills[depth].skill_list[skill].type"
            [skill_levels]="this.skills[depth].skill_list[skill].levels"
            [skill_colour]="this.skills[depth].skill_list[skill].colour"
          >
          </app-skill-button>
        </span>
      </td>
    </tr>
  </table>
</div>

<br/><br/>

<div class="div-skills">
    <img style="vertical-align: middle;" src="../../../assets/images/skill_trees/skill_tree_defense.png"/>
    <button class="button-skills top center">
        <span class="hovertext">
            Être capable de porter des armures légères en combat.
        </span>
    </button>
    <button class="button-skills middle left">
        <span class="hovertext">
            Être capable de porter des armures lourdes en combat.
        </span>
    </button>
    <button class="button-skills middle center">
        <span class="hovertext">
            Mobile - Augmenter sa mobilité en combat.<br/><br/>
            Bonus - Chaque début de tour, un PA est ajouté qui ne peut être consommé que pour du mouvement.<br/>
            Ce mouvement peut être marcher, courir, ou sprinter.<br/>
            Ce PA ajouté peut être consommé pour gagner un +1 à l'esquive pour un tour global, à la place du mouvement.<br/>
        </span>
    </button>
    <button class="button-skills middle right">
        <span class="hovertext">
            Esquive snowball.<br/><br/>
            Si une esquive réussi de 5 ou plus, gagne 2 PR et +1 à l'esquive jusqu'au début du prochain tour. +1 à l'esquive cummulable, pas le gain de PR.
        </span>
    </button>
    <button class="button-skills bot xleft">
        <span class="hovertext">
            Bloquer une attaque et réduire les dommages.<br/><br/>
            Requiert un bouclier.<br/>
            Consomme 1 PR pour gagner +1 en défense pour une attaque.<br/>
            Activable quand l'attaque touche, mais avant que les dommages soient lancés.<br/>
            Niveau 2 : Avec un bouclier équippé, gagne +1 permanent en défense.<br/>
        </span>
    </button>
    <button class="button-skills bot left">
        <span class="hovertext">
            Utiliser son action pour augmenter sa défense.<br/><br/>
            Consomme 2 PA pour obtenir +1 à l'esquive et +1 à la défense jusqu'au début de son prochain tour.<br/>
            Niveau 2 : Consomme 1 PA et non 2.<br/>
        </span>
    </button>
    <button class="button-skills bot center">
        <span class="hovertext">
            Intervention - Utiliser sa réaction pour prendre une attaque à la place de quelqu'un.<br/><br/>
            Consomme 2 PR pour échanger de place avec un allié et prendre l'attaque à sa place (prend les dégâts sans chance d'esquive).<br/>
            Activable quand un allié au contact (1 case) est cible d'une attaque (mêlée, distance, ou magie ciblée), et limité à une fois par tour de créature.<br/>
        </span>
    </button>
    <button class="button-skills bot right">
        <span class="hovertext">
            Évasion - Éviter une attaque.<br/><br/>
            Consomme 1 PR pour gagner +3 en esquive pour une attaque.<br/>
            Activable quand l'attaque est annoncée mais avant que le toucher soit lancé.<br/>
            Niveau 2 : +1 permanent à l'esquive.
        </span>
    </button>
    <button class="button-skills xbot left">
        <span class="hovertext bot-line">
            Résilient - Résitance à la douleur.<br/><br/>
            Quand la jauge de douleur atteint 10 (ou plus), le personnage reste conscient pour encore 2 tours.<br/>
            Le personnage doit encore lancer les dés de résistance pour savoir ses PA pour le tour, comme s'il était à 8 ou plus de douleur.<br/>
            À partir du 3e tour, le dé de douleur devient un d10, et s'il rate le personnage tombe inconscient.<br/>
            Niveau 2 : Le personnage reste conscient pour 4 tours et non 2.<br/>
        </span>
    </button>
</div>
<br/>
<br/>
<br/>
