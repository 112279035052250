<h3 *ngIf="langue.l == 'fr'">Types</h3>
<h3 *ngIf="langue.l == 'en'">Types</h3>

<p *ngIf="langue.l == 'fr'">
    Armes mêlées : force<br/>
    Armes à distance: dextérité<br/>
    Armes 1H tranchante : finesse (au choix, force ou dextérité)<br/>
    Les armes simples permettent un type d'attaque (soit légère, soit moyenne, soit lourde),
    et les armes versatiles permettent 2 types d'attaques (soit légère + moyenne, soit moyenne + lourde).<br/>
    <b>Il faut 3 points dans l'arbres de compétences d'attaque pour savoir utiliser les armes versatiles.</b><br/>
    Les différences entre les deux types d'armes sont principalement le prix, l'accessibilité, et l'entraînement nécessaire.<br/>
    Toutes les armes tranchantes peuvent faire une attaque improvisée en dégât contondants.
</p>
<p *ngIf="langue.l == 'en'">
    Melee weapons : strength<br/>
    Ranged weapons: dexterity<br/>
    One-handed slashing weapon : finesse (choice, strength or dexterity)<br/>
    Simple weapons allow for one attack type (either light, medium or heavy),
    while versatiles weapons allow for 2 attack types (either light + medium, or medium + heavy).<br/>
    You need 3 points in the attack skill tree to unlock using versatile weapons.<br/>
    The main differences between the two weapon types are the price and accessibility.<br/>
    All slashing weapons can make an improvised attack for bludgeoning damage.
</p>

<h3 *ngIf="langue.l == 'fr'">Exemples</h3>
<h3 *ngIf="langue.l == 'en'">Examples</h3>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Attaques</th>
            <th>Exemples d'armes</th>
            <th>Dé de dégâts</th>
            <th>Restrictions</th>
            <th>Points d'actions (PA)</th>
            <th>Prix des armes</th>
        </tr>
        <tr>
            <td>Poings</td>
            <td>mains nues</td>
            <td>d1 - 2</td>
            <td>/</td>
            <td>2</td>
            <td>ton SANG</td>
        </tr>
        <tr>
            <td>Attaque improvisée</td>
            <td>couteau, gourdin, chaise</td>
            <td>d2 - 2</td>
            <td>/</td>
            <td>3</td>
            <td>/</td>
        </tr>
        <tr>
            <td>Attaque légère</td>
            <td>dague, bâton, arc court, arbalète de poing*</td>
            <td>d4</td>
            <td>/</td>
            <td>2</td>
            <td>50</td>
        </tr>
        <tr>
            <td>Arme versatile légère-moyenne</td>
            <td>épée courte, scimetaire, arc recourbé</td>
            <td>/</td>
            <td>3 points dans l'arbre d'attaque</td>
            <td>/</td>
            <td>150</td>
        </tr>
        <tr>
            <td>Attaque moyenne</td>
            <td>épée longue, mace, arc long, arbalète*</td>
            <td>d6</td>
            <td>min 3 force</td>
            <td>3</td>
            <td>100</td>
        </tr>
        <tr>
            <td>Arme versatile moyenne-lourde</td>
            <td>claymore, hache, marteau</td>
            <td>/</td>
            <td>3 points dans l'arbre d'attaque</td>
            <td>/</td>
            <td>250</td>
        </tr>
        <tr>
            <td>Attaque lourde</td>
            <td>Zweihänder (espadon), hallebarde, maul</td>
            <td>d8</td>
            <td>min 4 force + 2H</td>
            <td>4</td>
            <td>200</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Attacks</th>
            <th>Weapon examples</th>
            <th>Damage die</th>
            <th>Restrictions</th>
            <th>Action points (PA)</th>
        </tr>
        <tr>
            <td>Nothing</td>
            <td>bare hands</td>
            <td>d1 - 2</td>
            <td>/</td>
            <td>2</td>
        </tr>
        <tr>
            <td>Improvised attacks</td>
            <td>knife, club</td>
            <td>d2 - 2</td>
            <td>/</td>
            <td>2</td>
        </tr>
        <tr>
            <td>Light attacks</td>
            <td>dagger, short bow, quarterstaff</td>
            <td>d4</td>
            <td>/</td>
            <td>2</td>
        </tr>
        <tr>
            <td>Versatile light or medium</td>
            <td>scimetar, long bow</td>
        </tr>
        <tr>
            <td>Medium attacks</td>
            <td>sword, mace, spear, crossbow*</td>
            <td>d6</td>
            <td>min 3 str</td>
            <td>3</td>
        </tr>
        <tr>
            <td>Versatile medium or heavy</td>
            <td>claymore, greataxe, great warhammer</td>
        </tr>
        <tr>
            <td>Heavy attacks</td>
            <td>Zweihänder (greatsword), halberd, maul</td>
            <td>d8</td>
            <td>min 4 str + 2H</td>
            <td>4</td>
        </tr>
    </table>
</div>

<p *ngIf="langue.l == 'fr'">
    *= L'arbalète ignore la restriction de Force, mais demande une autre action (1PA) pour recharger l'arbalète
</p>
<p *ngIf="langue.l == 'en'">
    *= The crossbow ignores the force restriction, but requires another action (1AP) to recharge.
</p>

<h3 *ngIf="langue.l == 'fr'">Attaquer à une main ou deux mains ?</h3>
<h3 *ngIf="langue.l == 'en'">Attacking one-handed or two-handed ?</h3>

<p *ngIf="langue.l == 'fr'">
    Pour les armes de contact :<br/>
    Si une arme peut faire une attaque légère, alors elle ne peut pas être tenue à deux mains pour une attaque.<br/>
    Une arme doit forcément être tenue à deux mains faire effectuer une attaque lourde.<br/>
    Les autres armes peuvent être tenues à une ou deux mains.<br/>
    Si une arme est tenue à deux mains, alors l'attaque gagne un bonus selon la caractéristique utilisée par l'attaquant :<br/>
    - +1 aux dégâts si l'attaquant utilise sa Force<br/>
    - +1 au toucher si l'attaquant utilise sa Dextérité.<br/>
    Les attaques lourdes, nécessitant deux mains sur l'arme pour être effectuées, profitent donc toujours du bonus.<br/>
    <br/>
    Pour les armes à distance :<br/>
    Les arcs ont tous besoin de deux mains pour faire des attaques.<br/>
    Les arbalètes ont besoin de soit une main soit deux selon la taille de l'arbalète,
    ignorent les restrictions de force pour les différentes attaques,
    et demandent une action supplémentaire pour recharger (1PA).<br/>
    <br/>
    Pour les armes d'aste :<br/>
    TBD
</p>
<p *ngIf="langue.l == 'en'">
    If a weapon can make light attacks, then it cannot be held in two hands.<br/>
    If a two-handed weapon is used one-handed, the attack takes a -3 to hit.<br/>
    If a one-handed weapon is used two-handed, the attack gains a +1 to hit.<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Pas assez de force ?</h3>
<h3 *ngIf="langue.l == 'en'">Not enough strength ?</h3>

<p *ngIf="langue.l == 'fr'">
    Si une personne veut utiliser une arme sans remplir la condition de Force, alors :<br/>
    - si il manque 1 de Force, alors l'attaque prend -1 au toucher.<br/>
    - si il manque 2 de Force, l'attaque prend -3 au toucher et -1 aux dégâts.<br/>
    - si il manque 3 ou plus de Force, la personne ne peut pas utiliser l'arme.<br/>
</p>
<p *ngIf="langue.l == 'en'">
    If a person wants to use a weapon without fulfilling the Strength requirement, then :<br/>
    - if they lack 1 Strength, then the attack takes -1 to hit.<br/>
    - if they lack 2 Strength, then the attack takes -3 to hit and -1 to damage.<br/>
    - if they lack 3 Strength or more, the person cannot use this weapon.<br/>
</p>

<h3 *ngIf="langue.l == 'fr'">Distance limite pour les armes à distance</h3>
<h3 *ngIf="langue.l == 'en'">Limit range for ranged weapons</h3>

<p *ngIf="langue.l == 'fr'">
    Chaque type d'arme à distance possède deux fourchettes de distances correspondantes : la distance droite et la distance en cloche.<br/>
    <br/>
    Pour la distance droite :<br/>
    - tirer une cible plus proche que la limite inférieure impose un -2 au toucher.<br/>
    - tirer une cible plus loin que la limite supérieure impose un -3 au toucher.<br/>
    - tirer une cible plus loin que le double de la limite supérieure est impossible.<br/>
    - tirer <b>requiert une ligne de vue</b> (cf <a routerLink="/system/attacking/hitting" routerLinkActive="activebutton">attaques à distance</a>).<br/>
    <br/>
    Pour la distance en cloche :<br/>
    - tirer une cible plus proche que la limite inférieure est impossible.<br/>
    - tirer une cible plus loin que la limite supérieure impose un -3 au toucher.<br/>
    - tirer une cible plus loin que le double de la limite supérieure est impossible.<br/>
    - toute attaque en cloche subit un -1 au toucher.<br/>
    - tirer <b>ne requiert pas</b> une ligne de vue.<br/>
    - tirer <b>requiert</b> de la hauteur disponible (impossible avec un plafond).<br/>
    - tirer <b>requiert</b> la compétence dans l'arbre d'attaque.<br/>
</p>

<table>
  <tr>
    <th>Malus de distances</th>
    <td>d &#60; min</td>
    <td>min &#60;= d &#60;= max</td>
    <td>max &#60; d &#60;= 2x max</td>
    <td>2x max &#60; d</td>
  </tr>
  <tr>
    <td>droite</td>
    <td>-2</td>
    <td>/</td>
    <td>-3</td>
    <td>impossible</td>
  </tr>
  <tr>
    <td>en cloche</td>
    <td>impossible</td>
    <td>-1</td>
    <td>-4</td>
    <td>impossible</td>
  </tr>
</table>

<table>
  <tr>
    <th>Type d'arme</th>
    <th>Distance droite</th>
    <th>Distance en cloche</th>
  </tr>
  <tr>
    <td>armes de contact</td>
    <td>1 cases / 1.5m</td>
    <td>-</td>
  </tr>
  <tr>
    <td>armes d'aste</td>
    <td>2 cases / 3m</td>
    <td>-</td>
  </tr>
  <tr>
    <td>fouet</td>
    <td>3 cases / 4.5m</td>
    <td>-</td>
  </tr>
  <tr>
    <td>arc court</td>
    <td>1-10 cases / 15m</td>
    <td>5-15 cases</td>
  </tr>
  <tr>
    <td>arbalète de poing</td>
    <td>1-10 cases / 15m</td>
    <td>10-20 cases</td>
  </tr>
  <tr>
    <td>arc recourbé</td>
    <td>2-15 cases / 22.5m</td>
    <td>10-20 cases</td>
  </tr>
  <tr>
    <td>arbalète lourde</td>
    <td>2-20 cases / 30m</td>
    <td>15-25 cases</td>
  </tr>
  <tr>
    <td>arc long</td>
    <td>2-20 cases / 30m</td>
    <td>10-30 cases</td>
  </tr>
</table>

<p *ngIf="langue.l == 'en'">
    Every weapon type has a corresponding distance.<br/>
    For the weapons without projectiles, it is the distance reachable by the weapon.<br/>
    For the weapons with projectiles, it is the distance until which a character can shoot without penalty.<br/>
    For these weapons, it is also possible to shoot further with a -3 to hit.
    The maximum distance to try to shoot is twice the indicated distance.<br/>
    <br/>
    melee weapons : 1 hex (1.5m = 2 steps)<br/>
    polearms : 2 hex (3m = 4 steps)<br/>
    whip : 3 hex (4.5m = 6 steps)<br/>
    shortbow : 10 hex (15m = 20 steps)<br/>
    crossbow : 15 hex (22.5m = 30 steps)<br/>
    longbow : 30 hex (45m = 60 steps)<br/>
</p>
