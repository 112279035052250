<p *ngIf="langue.l == 'fr'">
    La magie élémentaire a une forte influence sur le monde économique.<br/>
    Un certains nombre de métiers peuvent être facilités par certaines magies,
    et d'autres métiers ne sont possibles que par la magie.<br/>
    Sauf mention du contraire, les métiers décrits sont au service de la ville, et non de la Citadelle,
    même si la plupart possèdent des salles dédiées à la Citadelle.<br/>
</p>
<p *ngIf="langue.l == 'en'">

</p>

<h3 *ngIf="langue.l == 'fr'">Télépathes</h3>
<h3 *ngIf="langue.l == 'en'">Telepaths</h3>

<p *ngIf="langue.l == 'fr'">
    Les télépathes servent à communiquer des messages rapidement sur de longues distances.<br/>
    La magie d'esprit est nécessaire pour ce métier.<br/>
    Il existe une dizaine de télépathes entraînés répartis sur le continent, et entre une et deux dizaines d'apprentis.<br/>
    Les télépathes portent un uniforme vert foncé représentant leur magie, dont la forme s'adapte en fonction de leur localisation.<br/>
    Les télépathes jurent serment de silence sur les messages transportés.<br/>
    <br/>
    Fun fact : Le métier de télépathe a vu le jour durant la guerre des elfes
    quand les sylvains et sombres refusaient de se parler en face et ont décidé d'utiliser la magie pour communiquer.<br/>
</p>
<p *ngIf="langue.l == 'en'">

</p>

<h3 *ngIf="langue.l == 'fr'">Guetteurs</h3>
<h3 *ngIf="langue.l == 'en'">Watchers</h3>

<p *ngIf="langue.l == 'fr'">
    Les guetteurs servent à surveiller les endroits sensibles pour détecter toute magie non authorisée.<br/>
    La magie de feu est nécessaire pour ce métier, et celle de purification est un plus (focus sur la détection de magie).<br/>
    Il existe une douzaine de guetteurs entraînés dans Mekair, et peu dans d'autres villes.<br/>
    Les guetteurs ne portent pas d'uniforme particulier.<br/>
    Ils partrouille les marchés, surveillent les examens, peuvent être embauchés pour surveiller un événement, etc.<br/>
</p>
<p *ngIf="langue.l == 'en'">

</p>

<h3 *ngIf="langue.l == 'fr'">Architectes</h3>
<h3 *ngIf="langue.l == 'en'">Architects</h3>

<p *ngIf="langue.l == 'fr'">
    Les architectes servent à concevoir les bâtiments et à encadrer leur construction.
    Ils sont aussi responsables du maintient des bâtiments existants.<br/>
    Aucune magie n'est nécessaire, mais les magies de terre et de glace sont des plus.<br/>
    La plupart des architectes travaillent en pair, et il est courant qu'un seul des deux n'ait de magie adaptée.<br/>
</p>
<p *ngIf="langue.l == 'en'">

</p>

<h3 *ngIf="langue.l == 'fr'">Soigneurs</h3>
<h3 *ngIf="langue.l == 'en'">Healers</h3>

<p *ngIf="langue.l == 'fr'">
    Les soigneurs servent à soigner les gens, gratuitement.<br/>
    Une magie de soin est nécessaire (lumière ou eau), et la magie de purification est un plus (focus sur le soin).<br/>
    Les soigneurs portent un bandeau de la couleur de leur magie pour identification facile pour les urgences.<br/>
</p>
<p *ngIf="langue.l == 'en'">

</p>

<h3 *ngIf="langue.l == 'fr'">Agriculteurs</h3>
<h3 *ngIf="langue.l == 'en'">Farmers</h3>

<p *ngIf="langue.l == 'fr'">
    Les agriculteurs servent à fournir de la nourriture et d'autres produits naturels aux villes environnantes.<br/>
    Aucune magie n'est nécessaire, mais les magies de nature, croissance, animaux, terre et eau sont des plus.<br/>
    
</p>
<p *ngIf="langue.l == 'en'">

</p>
