<p *ngIf="langue.l == 'fr'">
    Les Elfs sombres ont beaucoup de mal à se remettre de la guerre, sans le support des sylvains<br/>
    Ils demandent de l'aide aux lyunes et nekali, et commencent à former les premières routes marchandes<br/>
    
    <br/>
    <b>Politique</b><br/>
    elfes sombres = familigarchie
</p>
<p *ngIf="langue.l == 'en'">

</p>
