<p *ngIf="langue.l == 'fr'">
    La vision élémentaire est disponible à toute personne possédant (au moins) une magie pure.<br/>
    Pour activer sa vision élémentaire, il faut choisir une de ses magies pures et se
    <a routerLink="/system/states" routerLinkActive="activebutton">concentrer</a>,
    comme sur un sort de niveau 1 pour sa magie majeure, de niveau 2 pour sa magie mineure.<br/>
    La vision élémentaire n'existe pas pour les magies combinées.<br/>
    Une fois activée, la vision élémentaire remplace la vision normale.
    L'ensemble des objets sont alors comme d'un blanc absolu où les profondeurs sont indistinguables.
    Seul l'élément choisi ressort et possède sa couleur
    (cf <a routerLink="/lore/magic/elemental" routerLinkActive="activebutton">magie</a>).<br/>
    La personne avec la vision activée détecte plus de détails sur la forme et le mouvement de son élément,
    et souvent donc des choses en contact avec l'élément.<br/>
    La personne avec la vision élémentaire activée ressent tout son élément en trois dimensions,
    même si la personne ne peut qu'en voir une partie ou qu'une surface avec sa vision "normale".<br/>
    Avec quelques tours de concentration, la personne peut voir son élément à travers des objets (murs, sacs, porte).
</p>
<p *ngIf="langue.l == 'en'">
    The elemental vision is available to anyone with (at least) a pure magic.<br/>
    To activate their elemental vision, someone has to choose one of their pure magic and focus,
    as if for a level 1 spell for their major magic, and a level 2 spell for their minor magic.
    (see <a routerLink="/system/states" routerLinkActive="activebutton">concentrated state</a>)<br/>
    The elemental vision does not exist for combined magics.<br/>
    Once activated, the elemental vision replaces normal vision.
    Every object is then a sort of absolute white where depths are imperceptible.
    Only the chosen element stands out and is of its colour
    (see <a routerLink="/lore/magic/elemental" routerLinkActive="activebutton">magic</a>).<br/>
    The person with the vision activated detects more details about the shape and movement of their elements,
    and often also of the things in contact with the element.<br/>
    The person with the elemental vision activated feels their whole element in three dimensions,
    even if the person can only see part or a surface with their "normal" vision.<br/>
    With several turns of concentration, the person can see their element through objects (walls, bags, doors).
</p>