<p *ngIf="langue.l == 'fr'">
    Pour l'instant, la plupart de la faune est identique à celle qu'on connait,
    au détail près que certains animaux peuvent posséder une magie.<br/>
    La flaure est aussi relativement similaire, avec quelques changements et nouveautés,
    et les plantes ne peuvent pas posséder de magie.<br/>
    Certaines plantes ont des propriétés spéciales comme aider à dormir,
    favoriser la cicatrisation, ou causer des problèmes digestifs.
</p>
<p *ngIf="langue.l == 'en'">

</p>
