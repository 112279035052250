<nav class="band band2">
  <a routerLink="/lore/history" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Histoire</menu-button>
      <menu-button *ngIf="langue.l == 'en'">History</menu-button>
  </a>
  <a routerLink="/lore/religion" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Croyances</menu-button>
      <menu-button *ngIf="langue.l == 'en'">Beliefs</menu-button>
  </a>
  <a routerLink="/lore/races" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Races</menu-button>
      <menu-button *ngIf="langue.l == 'en'">Races</menu-button>
  </a>
  <a routerLink="/lore/magic" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Magie</menu-button>
      <menu-button *ngIf="langue.l == 'en'">Magic</menu-button>
  </a>
  <a routerLink="/lore/geography" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Geographie</menu-button>
      <menu-button *ngIf="langue.l == 'en'">Geography</menu-button>
  </a>
  <a routerLink="/lore/living" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Vie au quotidien</menu-button>
      <menu-button *ngIf="langue.l == 'en'">Day to day life</menu-button>
  </a>
  <a routerLink="/lore/languages" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Langues</menu-button>
      <menu-button *ngIf="langue.l == 'en'">Languages</menu-button>
  </a>
  <a routerLink="/lore/fauna-flora" class="button-band" routerLinkActive="activebutton">
      <menu-button *ngIf="langue.l == 'fr'">Faune et flore</menu-button>
      <menu-button *ngIf="langue.l == 'en'">Fauna and flora</menu-button>
  </a>
</nav>
<router-outlet></router-outlet>
