<br/><br/>
<div class="div-table">
  <table class="skill-table" >
    <tr *ngFor="let depth of this.depths">
      <td *ngFor="let skill of this.skills[depth].name_list">
        <span *ngIf="skill !== 'nothing'">
          <app-skill-button
            [skill_name]="skill"
            [skill_category]="this.skills[depth].skill_list[skill].category"
            [skill_depth]="depth"
            [header]="this.skills[depth].skill_list[skill].header"
            [skill_type]="this.skills[depth].skill_list[skill].type"
            [skill_levels]="this.skills[depth].skill_list[skill].levels"
            [skill_colour]="this.skills[depth].skill_list[skill].colour"
          >
          </app-skill-button>
        </span>
      </td>
    </tr>
  </table>
</div>

<br/>
<div class="div-skills">
    <img src="../../../assets/images/skill_trees/skill_tree_magic.png"/>
    <button class="button-skills center xtop">
        <span class="hovertext">
            Permet la méditation.<br/><br/>
            Méditer une heure (au lieu de se reposer une heure) redonne 1 point d'énergie
            et 2 points d'énergie temporaire (au lieu de 2 points d'énergie).<br/>
            La méditation rapide permet d'obtenir 1 point d'énergie temporaire en 10 minutes.
            La méditation rapide ne peut être faite qu'une fois par heure.
        </span>
    </button>
    <button class="passive-skills xtop xleft">
        <span>
            Adaptation<br/>
            Décaler des points entre les dés selon la profondeur dans l'arbre.*
        </span>
    </button>
    <button class="passive-skills xtop xright">
        <span>
            Investissement<br/>
            Diminuer le coût des sorts selon les points investis dans l'arbre.*
        </span>
    </button>
    <button class="button-skills top left">
        <span class="hovertext">
            Perturber - Empêcher un mage d'utiliser sa magie.<br/><br/>
            Consomme 1 PR pour essayer d'enlever un dé à un mage visible lançant un sort.<br/>
            Compétition de jets de sensitivité.<br/>
            Activable quand un ennemis visible commence à lancer un sort.
        </span>
    </button>
    <button class="button-skills top center">
        <span class="hovertext">
            Infusion - Sort pour augmenter un objet physique.<br/><br/>
            Manipulation (3 PA) pour lancer un sort sur un objet.
            Les résultats varient selon l'élément de la magie,
            les caractéristiques du sort et la volonté du lanceur.<br/>
        </span>
    </button>
    <button class="button-skills top right">
        <span class="hovertext">
            Infatiguable<br/><br/>
            Ignore tous les malus de magie liés à l'état fatigué
            (ne change pas les malus aux d12 et dégâts).
        </span>
    </button>
    <button class="button-skills middle xleft">
        <span class="hovertext">
            Transfert - Voler le dé perdu.<br/><br/>
            Après une perturbation réussie, le mage perturbant gagne le dé
            perdu de la victime à sa prochaine manipulation.
        </span>
    </button>
    <button class="button-skills middle left">
        <span class="hovertext">
            Réaction magie - Utiliser sa réaction pour utiliser sa magie.<br/><br/>
            Consomme 2 PR pour lancer un sort normalement.
        </span>
    </button>
    <button class="button-skills middle center">
        <span class="hovertext">
            Détermination - Pouvoir relancer ses dés.<br/><br/>
            Peut relancer gratuitement 1 dé.<br/>
            Activable pendant une manipulation.<br/>
            Utilisable une fois par tour.
        </span>
    </button>
    <button class="button-skills middle right">
        <span class="hovertext">
            Focus - Consommer de l'énergie additionnelle pour un bonus au sort<br/><br/>
            Consomme 1 point d'énergie pour ajouter 1d4 au résultat d'un sort.<br/>
            Activable après avoir calculé la puissance du sort.<br/>
            Utilisable une fois par manipulation.<br/>
        </span>
    </button>
    <button class="button-skills middle xright">
        <span class="hovertext">
            Endurance - Résistance à la fatigue<br/><br/>
            Le maximum d'énergie est maintenant 12.<br/>
            En plus, le malus de fatigue aux d12 sont ignorés.
        </span>
    </button>
    <button class="button-skills bot left">
        <span class="hovertext">
            Absorption - Réaction pour absorber la magie d'un ennemi.<br/><br/>
            Consomme 2 PR pour essayer d'aborber l'énergie du sort de l'adversaire.<br/>
            Lance les dés d'un sort et compare-les au sort de l'adversaire.<br/>
            Activable quand un sort est dirigé vers soi.
        </span>
    </button>
    <button class="button-skills bot center">
        <span class="hovertext">
            Mastermind - Les concentrations coûtent moins et les sorts sont plus forts<br/><br/>
            Les niveaux des concentrations sont un niveau de moins que les sorts.<br/>
            De plus, tous les sorts commencent à 1 sur une caractéristique.
        </span>
    </button>
    <button class="button-skills bot right">
        <span class="hovertext">
            Transe - Utiliser son énergie pour avoir plus de dés.<br/><br/>
            Consomme 1PA et 1 point d'énergie pour activer la transe.
            Avec une transe active, le joueur choisi une quantité de points d'énergie à convertir en dés additionnels pour une manipulation.<br>
            Peut être activé avant ou après le lancé des dés pour une manipulation.
            <br/>
            (Rappel : Ne peut pas dépenser de points au delà du maximum d'énergie, et le personnage tombe inconscient si son énergie atteint 0.)
        </span>
    </button>
    <button class="button-skills xbot center">
        <span class="hovertext bot-line">
            Supériorité<br/><br/>
            Gagne 1d6 à toute manipulation.<br/>
        </span>
    </button>
</div>
<br/>
<p>
    * : cf <a routerLink="/character/skills/explanation" routerLinkActive="activebutton">explication des compétences</a><br/>
</p>
<!-- for the hover text to appear within the background image -->
