<p *ngIf="langue.l == 'fr'">
    Les elfs sylvains et les flyhns sont les premières races à se développer, et les deux clans sont rivaux.<br/>
    Les flyhns s'installent près des montagnes et les sylvains dans la forêt au sud. Entre eux se trouvent des plaines.<br/>
    Plusieurs conflits sur le droit de posséder les plaines, et ils décident de les partager en deux.<br/>
    Les elfs commencent ensuite à s'étendre le long de la forêt vers le nord est.<br/>
    La forêt du nord est différente, plus sombre et rude, et les elfs vivant là sont progressivement transformés. Les elfs sombres sont nés.<br/>
    Les flyhns découvrent une zone de désert/savane au nord des montagnes et décident de rester là où ils sont.<br/>
    <br/>
    <b>Politique</b><br/>
    elfs sylvains = royauté monarchique patriarchique héréditaire<br/>
    flyhns = rien, puis royauté monarchique matriarchique héréditaire pour l'instant *cough* semi-copié des sylvains
</p>
<p *ngIf="langue.l == 'en'">

</p>