
<div *ngIf="!entered">
  <form>
    <input [value]="passphrase" (input)="passphrase=getValue($event)" type="text" placeholder="Entrer le mot de passe">
    <button (click)="go_to_page()" class="primary" type="button">-></button>
  </form>
</div>

<div *ngIf="entered">
  <router-outlet></router-outlet>
</div>
