<p *ngIf="langue.l == 'fr'">
    Les Elfes Sylvains, humains elfiques vivant dans la forêt du sud.<br/>
    Physiquement, ils sont grands, fins, aux oreilles pointues, aux cheveux clairs et la peau plutôt tannée.
    Un Elf sylvain mesure autour de 1m80-2m10.<br/>
    Espérance de vie : 150 ans.<br/>
    Affinité avec la magie de nature (verte)<br/>
    La culture est centrée sur la fierté et le mérite, la protection de la forêt et la hiérarchie.<br/>
    [Thèmes/inspirations : haut elfes, elfes des bois classiques]<br/>
    <br/>
    Mentalité<br/>
    La hiérarchie est la base de tout gouvernement, et sans gouvernement, un système ne peut que s'effondrer.<br/>
    Le respect est nécessaire, surtout envers ses supérieurs.<br/>
    Avec les Flyhn, les Elfes Sylvains sont une race supérieure,
    et les Elfes Sombres sont des Elfes Sylvains corrompus qui font honte à leur nom d'elfe.<br/>
    La nature fait partie intégrante de la vie, et il faut à tout prix protéger la forêt et toute autre forme de nature.
    Les promesses sont ce qui différencient les être sensés des êtres sauvages.<br/>
    <br/>
    Background<br/>
    Arrivés en premier avec les Flyhn en Destalyr,
    ils se voient comme les propriétaires du territoire et s'étendent sur toute la forêt très tôt.
    Après l'apparition des Elfes Sombres, ils sont obligés de battre en retraite,
    mais leur déclare la guerre pour avoir volé leur forêt.
    Elle dure plusieurs dizaines d'années, beaucoup de vies sont perdues,
    beaucoup de la forêt est ravagée et/ou piégée par les deux camps.
    Les Elfes Sylvains se rendent compte de la destruction et propose une trêve au bénéfice de la survie de la forêt,
    acceptée par les Elfes Sombres qui y sont toujours liés.
    Depuis ils ont de bonnes relations avec les Flyhn,
    considèrent les Nekali et les Humains comme des enfants,
    et évitent les Elfes Sombres le plus possible.
</p>

<p *ngIf="langue.l == 'en'">

</p>
