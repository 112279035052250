import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sercret-etreks',
  templateUrl: './sercret-etreks.component.html',
  styleUrls: ['./sercret-etreks.component.css']
})
export class SercretEtreksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
