<nav class="band band3">
    <a routerLink="/character/skills/attack" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Attaque</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Attack</menu-button>
    </a>
    <a routerLink="/character/skills/defense" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Défense</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Defense</menu-button>
    </a>
    <a routerLink="/character/skills/magic" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Magie</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Magic</menu-button>
    </a>
    <a routerLink="/character/skills/explanation" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Explication</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Explanation</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>