<button class="button back-button">
    <a routerLink="/colours-of-magic/uses">
        <i class="fa-sharp fa-solid fa-caret-left"></i>
        Retour
    </a>
</button>

<div class="magic-pres">
    <div class="magic-title">
        Ténèbres
    </div>
    <div class="magic-stats">
        <div class="magic-purity">
            <div class="magic-active">pure</div>
            <div class="magic-inactive">combinée</div>
        </div>
        <div class="magic-tangibility">
            <div class="magic-inactive">Tangible</div>
            <div class="magic-active">intangible</div>
        </div>
        <div class="magic-det">
            <div>DET</div>
            <div>-</div>
        </div>
    </div>
</div>

<p>
    La magie de ténèbres est incapable de faire des dégâts.
</p>
