<p *ngIf="langue.l == 'fr'">
    Nouvelles races apparaissent : Lyunes et Nekali.<br/>
    Lyunes dans la jungle du centre, et Nekali dans les steppes au nord ouest.<br/>
    <br/>
    <b>Politique</b><br/>
    Lyunes = sans gouvernement<br/>
    Nekali = sans gouvernement
</p>
<p *ngIf="langue.l == 'en'">

</p>