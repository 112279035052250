<p *ngIf="langue.l == 'fr'">
    Pour savoir comment survivre, il faut commencer par savoir comment mourir.<br/>
    Il existe plusieurs façon de mourir dans Destalyr.
</p>
<p *ngIf="langue.l == 'en'">
    To know how to survive, one must first know how to die.<br/>
    There are several ways to die in Destalyr.
</p>

<h3 *ngIf="langue.l == 'fr'">Exécution</h3>
<h3 *ngIf="langue.l == 'en'">Execution</h3>

<p *ngIf="langue.l == 'fr'">
    Il est possible d'exécuter une personne si elle est inconsciente et qu'on est en contact avec elle.<br/>
    Une autre personne en contact peut empêcher l'exécution, rendant son utilisation difficile en combat.<br/>
    Pour exécuter quelqu'un, il faut aussi être en posséssion d'une arme ou moyen de tuer une personne.
</p>
<p *ngIf="langue.l == 'en'">
    It is possible to execute someone if they are unconscious and we are in contact with them.<br/>
    Another person in contact can prevent the execution, making its use limited in combat.<br/>
    In order to execute someone, one must also have a weapon or a mean to kill.
</p>

<h3 *ngIf="langue.l == 'fr'">Perdre son sang</h3>
<h3 *ngIf="langue.l == 'en'">Blood loss</h3>

<p *ngIf="langue.l == 'fr'">
    La perte de sang est une des morts les plus courantes.<br/>
    Les blessures se séparent en trois catégories : casse, coupures, et autres (brûlures, poison, etc.).<br/>
    Les coupures restent ouvertes jusqu'au premier soin reçu ou attention donnée. Pendant qu'une coupure est ouverte, la victime perd son sang.<br/>
    Dès le premier soin reçu, la plaie se ferme, mais si l'intensité est encore de 0 ou plus, la blessure n'est pas entièrement guérie.<br/>
    Une coupure peut aussi se fermer en passant quelques minutes (minutes = 2x intensité) à la fermer à la main (bandage + souture).<br/>
    Le sang coule d'une coupure ouverte mais pas d'une coupure fermée, même si la blessure est encore présente.
    La fermeture d'une coupure ne coûte rien et n'affaiblit pas le soin reçu.<br/>
    Comme ordre de grandeur, une coupure d'intensité 2 ou 3 n'est pas urgente, la victime peut finir son combat, trouver un endroit sécurisé et soigner sa blessure.<br/>
    Une coupure d'intensité 5 ou 6 pousse la victime à se soigner dès le combat fini.<br/>
    Avec une coupure d'intensité 8 ou 9, la victime va avoir besoin d'un soin avant la fin du combat.
</p>
<p *ngIf="langue.l == 'en'">
    Blood loss is the most classic death.<br/>
    
</p>

<h3 *ngIf="langue.l == 'fr'">Mort subite</h3>
<h3 *ngIf="langue.l == 'en'">Instant death</h3>

<p *ngIf="langue.l == 'fr'">
    Si un personnage reçoit une blessure d'intensité 11 ou plus, le personnage meurt sur le coup.<br/>
    La raison de la mort dépend de la blessure (décapitation, flèche dans le coeur, ...).
</p>
<p *ngIf="langue.l == 'en'">
    If a character receives a wound of intensity 11 or more, then the character dies instantly.<br/>
    The cause of death depends on the wound (decapitation, arrow to the heart, ...).
</p>
