<nav class="band band2">
    <a routerLink="/system/explanation" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Explication</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Explanation</menu-button>
    </a>
    <a routerLink="/system/weapons" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Armes</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Weapons</menu-button>
    </a>
    <a routerLink="/system/armors" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Armures</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Armors</menu-button>
    </a>
    <a routerLink="/system/gauges" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Jauges</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Gauges</menu-button>
    </a>
    <a routerLink="/system/attacking" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Attaquer</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Attacking</menu-button>
    </a>
    <a routerLink="/system/combat" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Combat</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Combat</menu-button>
    </a>
    <a routerLink="/system/magic" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Magie</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Magic</menu-button>
    </a>
    <a routerLink="/system/other-rolls" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Autres lancés</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Other rolls</menu-button>
    </a>
    <a routerLink="/system/healing" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">Soins & Repos</menu-button>
        <menu-button *ngIf="langue.l == 'en'">Healing & Rest</menu-button>
    </a>
    <a routerLink="/system/states" class="button-band" routerLinkActive="activebutton">
        <menu-button *ngIf="langue.l == 'fr'">États</menu-button>
        <menu-button *ngIf="langue.l == 'en'">States</menu-button>
    </a>
</nav>
<router-outlet></router-outlet>
