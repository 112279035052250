<br/>
<h3 *ngIf="langue.l == 'fr'">Page introuvable</h3>
<h3 *ngIf="langue.l == 'en'">Page not found</h3>

<p *ngIf="langue.l == 'fr'">
    Ceci n'est pas censé arriver.<br/>
    Vérifiez l'url si vous l'avez tapé à la main.<br/>
    Sinon, c'est une erreur que vous pouvez rapporter à l'adresse suivante : mephysto.phelles666&#64;gmail.com<br/>
    Incluez l'url et le chemin que vous avez suivi pour y arriver. Merci d'avance.
</p>

<p *ngIf="langue.l == 'en'">
    This wasn't supposed to happen.<br/>
    Check the url if you typed it by hand.<br/>
    If not, this might be a mistake that you can report to this address : mephysto.phelles666&#64;gmail.com<br/>
    Please include the current url and the path you took to get to it. Thank you in advance.
</p>
