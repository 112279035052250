<p *ngIf="langue.l == 'fr'">
    La plupart des créatures possèdent 7 statistiques qui les décrivent :
    Force, Résistance, Dextérité, Agilité, Perception, Éloquence, Sensitivité.
    <br/>

    <br/><b>Force</b><br/>
    La Force d'une créature décrit sa force physique, sa capacité à porter ou pousser des choses lourdes.<br/>
    La Force est utilisée pour manipuler des armes de mêlée.<br/>

    <br/><b>Résistance</b><br/>
    La Résistance d'une créature décrit sa résistance physique et mentale, sa capacité à supporter la douleur ou résister au poison.<br/>
    La Résistance est utilisée pour calculer la défense.<br/>

    <br/><b>Dextérité</b><br/>
    La Dextérité d'une créature décrit sa précision, sa capacité à effectuer des actions délicates.<br/>
    La Dextérité est utilisée pour manipuler des armes de distance et calculer les réactions.<br/>

    <br/><b>Agilité</b><br/>
    L'Agilité d'une créature décrit sa vitesse, sa capacité à se déplacer agilement et discrètement.<br/>
    L'Agilité est utilisée pour calculer l'esquive et l'initiative.<br/>

    <br/><b>Perception</b><br/>
    La Perception d'une créature décrit ses sens, sa capacité à récupérer des informations de l'environnement.<br/>
    La Perception est utilisée pour les investigations ou le pistage.<br/>

    <br/><b>Éloquence</b><br/>
    L'Éloquence d'une créature décrit son charisme, sa capacité à parler et être intéressant.<br/>
    L'Éloquence est utilisée pour les interactions sociales.<br/>

    <br/><b>Sensitivité</b><br/>
    La Sensitivité d'une créature décrit son lien à la magie, sa capacité à détecter, invoquer et manipuler les éléments.<br/>
    La Sensitivité est utilisée pour calculer les dés de magie.<br/>

</p>
<p *ngIf="langue.l == 'en'">

</p>
