import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-magic-uses-firewater',
  templateUrl: './magic-uses-firewater.component.html',
  styleUrls: ['./magic-uses-firewater.component.css']
})
export class MagicUsesFirewaterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
