<p *ngIf="langue.l == 'fr'">
    Les humains peuvent porter une armure à la fois, et un bouclier si ils ont une main de libre.<br/>
    Le métal étant un conducteur d'énergie magique puissant, les armures en métal peuvent perturber un mage.<br/>
    Si une magie de puissance totale supérieure ou égale à la valeur indiqué est lancée avec une armure en métal, l'énergie rebondit sur l'armure et peut blesser le mage.<br/>
    Tous les humains savent porter des vêtements en combat, mais il faut apprendre pour porter des armures, légères ou loudres
    (cf <a routerLink="/character/skills/defense" routerLinkActive="activebutton">compétences</a>).
</p>
<p *ngIf="langue.l == 'en'">
    Humans can wear one armor at a time, and a shield if they have a free hand.<br/>
    All metals are conductors of magic energy, therefore metal armors can prevent a mage from casting spells.<br/>
    If a spell is cast with a total power equal to or larger than the indicated number, the spell bounces back on the armor and can hurt the caster.<br/>
    Ever human can wear clothing in combat, but must learn to wear armor, light or heavy
    (see <a routerLink="/character/skills/defense" routerLinkActive="activebutton">skills</a>).
</p>

<h3 *ngIf="langue.l == 'fr'">Vêtements</h3>
<h3 *ngIf="langue.l == 'en'">Clothing</h3>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Niveau de l'armure</th>
            <th>Nom de l'armure</th>
            <th>Défense</th>
            <th>Prix</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Vêtements</td>
            <td>0</td>
            <td>50</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Vêtements épais</td>
            <td>1</td>
            <td>100</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Gambison</td>
            <td>2</td>
            <td>150</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Armor level</th>
            <th>Armor name</th>
            <th>Defense</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Clothes</td>
            <td>0</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Thick clothes</td>
            <td>1</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Gambison</td>
            <td>2</td>
        </tr>
    </table>
</div>

<h3 *ngIf="langue.l == 'fr'">Armures légères</h3>
<h3 *ngIf="langue.l == 'en'">Light armors</h3>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Niveau de l'armure</th>
            <th>Nom de l'armure</th>
            <th>Défense</th>
            <th>Restrictions</th>
            <th>Prix</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Armure de peau</td>
            <td>1</td>
            <td>75</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Armure de cuir</td>
            <td>2</td>
            <td>125</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Armure de cuir clouté</td>
            <td>3</td>
            <td>175</td>
        </tr>
        <tr>
            <td>1</td>
            <td>Bouclier léger</td>
            <td>+1 esquive</td>
            <td>1H</td>
            <td>50</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Armor level</th>
            <th>Armor name</th>
            <th>Defense</th>
            <th>Restrictions</th>
            <th>Price</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Light leather</td>
            <td>1</td>
            <td>75</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Reinforced leather</td>
            <td>2</td>
            <td>125</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Studded leather</td>
            <td>3</td>
            <td>175</td>
        </tr>
        <tr>
            <td>1</td>
            <td>Buckler (light shield)</td>
            <td>+1 evasion</td>
            <td>1H</td>
            <td>50</td>
        </tr>
    </table>
</div>

<h3 *ngIf="langue.l == 'fr'">Armures lourdes</h3>
<h3 *ngIf="langue.l == 'en'">Heavy armors</h3>

<div class="div-table" *ngIf="langue.l == 'fr'">
    <table>
        <tr>
            <th>Niveau de l'armure</th>
            <th>Nom de l'armure</th>
            <th>Défense</th>
            <th>Restrictions</th>
            <th>Limite de puissance de magie</th>
            <th>Prix</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Cotte de maille</td>
            <td>2</td>
            <td>agilité majorée à 3</td>
            <td>15</td>
            <td>100</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Armure lamellaire</td>
            <td>3</td>
            <td>min 4 force + agilité majorée à 3</td>
            <td>10</td>
            <td>150</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Demi plate</td>
            <td>4</td>
            <td>min 5 force + agilité majorée à 2</td>
            <td>10</td>
            <td>200</td>
        </tr>
        <tr>
            <td>4</td>
            <td>Armure de plates</td>
            <td>5</td>
            <td>min 5 force + agilité majorée à 2 + min 3 points dans l'arbre de défense</td>
            <td>10</td>
            <td>250</td>
        </tr>
        <tr>
            <td>1</td>
            <td>Bouclier lourd</td>
            <td>+2 esquive +1 défense</td>
            <td>min 4 force + 1H</td>
            <td>-</td>
            <td>100</td>
        </tr>
    </table>
</div>
<div class="div-table" *ngIf="langue.l == 'en'">
    <table>
        <tr>
            <th>Armor level</th>
            <th>Armor name</th>
            <th>Defense</th>
            <th>Restrictions</th>
            <th>Power limit of spells</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Chain armor</td>
            <td>2</td>
            <td>agility capped at 3</td>
            <td>15</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Lamellar armor</td>
            <td>3</td>
            <td>min 4 strength + agility capped at 3</td>
            <td>10</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Half plate</td>
            <td>4</td>
            <td>min 5 strength + agility capped at 2</td>
            <td>10</td>
        </tr>
        <tr>
            <td>4</td>
            <td>Full plate</td>
            <td>5</td>
            <td>min 5 strength + agility capped at 2</td>
            <td>10</td>
        </tr>
        <tr>
            <td>1</td>
            <td>Shield</td>
            <td>+2 evasion +1 defense</td>
            <td>min 4 strength + 1H</td>
            <td>-</td>
        </tr>
    </table>
</div>
