<h3 *ngIf="langue.l == 'fr'">Infusion</h3>
<h3 *ngIf="langue.l == 'en'">Infusion</h3>

<p *ngIf="langue.l == 'fr'">
    L'infusion correspond à une manipulation dont la cible est un objet
    et qui a pour but d'imbuer cet objet avec l'élément désiré (= élément de la manipulation).<br>
    Tous les éléments peuvent être utilisé en tant qu'infusion,
    et les effets peuvent varier grandement selon l'élément, la puissance de la manipulation, et la volonté de la source.<br>
    Une arme infusée gagne une propriété venant de son infusion : ses dégâts de bases restent inchangés (donc physiques)
    mais les attaques avec l'arme infusée apportent un point d'accumulation élémentaire (de l'élément d'infusion).<br>
    En parallèle de ce point élémentaire apporté, l'effet de l'infusion choisi par sa source peuvent apporter des modifications aux attaques,
    comme ajouter des dégâts élémentaires, déplacer la cible, infliger un état détrimentaire, ou bien avoir d'autres conséquences pour la cible.<br>
    Sauf contre-indication, un personnage ne peut apporter qu'un point d'accumulation élémentaire par attaque avec une magie pure, et 2 avec une magie combinée.
    Donc infuser une épée avec du feu pour faire +2 dégâts de feu ne va appliquer qu'une seule fois le point de feu sur la victime par attaque
    (si cette attaque touche et inflige des dégâts).
</p>
<p *ngIf="langue.l == 'en'">

</p>


<h3 *ngIf="langue.l == 'fr'">Buffs</h3>
<h3 *ngIf="langue.l == 'en'">Buffs</h3>

<p *ngIf="langue.l == 'fr'">
    Le buff correspond à une manipulation dont la cible est une créature (animale, humaine, élémentaire, ou autre)
    et qui a pour but d'imbuer cette créature avec l'élément désiré (= élément de la manipulation).<br>
    La plupart mais pas tous les éléments peuvent être source de buff,
    certains sont plus faciles à utiliser pour buffer que d'autres,
    et de manière générale, chaque élément ne peut buffer qu'une caractéristique de la créature
    (cf hexagramme ci dessous).<br>
    La difficulté est statique, mais la caractéristique buffée peut être modifiée.
    En revanche un sort ne peut buffer qu'une stat à la fois.<br>
    Les couleurs des caractéristiques représentent les difficultés à buffer par l'élément déterminé par la position dans l'hexagramme :
    vert est facile, jaune est difficile, rouge est très difficile, et noir est impossible.
</p>
<p *ngIf="langue.l == 'en'">

</p>


<h3 *ngIf="langue.l == 'fr'">Caractéristiques</h3>
<h3 *ngIf="langue.l == 'en'">Characteristics</h3>

<p *ngIf="langue.l == 'fr'">
    Les caractéristiques de la manipulation vont affecter les résultats ainsi : <br>
    - La Taille doit couvrir la cible entièrement (cf ordres de grandeur)<br>
    - La Distance indique la distance entre la source et la cible.
    De même que la conjuration, si ces deux s'éloignent de plus que cette distance, alors le lien devient dormant,
    et si la distance excède le double de la distance autorisée, alors le lien est rompu.<br>
    - La Précision détermine si l'infusion prend effet.
    Le test est effectué une fois au début du tour et consiste en un jet de précision (1d12 + Précision x2) DC11.<br>
    - L'Efficacité détermine la force des effets.
    Selon le type d'effet, le résultat suivra une des colonnes (ou une variation de ces colonnes) du tableau ci-dessous.<br>
    À noter que la colonne Très facile ne sera pas utilisée par les buffs mais seulement les infusions.
</p>
<p *ngIf="langue.l == 'en'">

</p>

<div class="div-table" *ngIf="langue.l == 'fr'">
  <table>
      <tr>
          <th>Efficacité</th>
          <th>Résultats Très facile</th>
          <th style="color: green;">Résultats Facile</th>
          <th style="color: rgb(255, 150, 0);">Résultats Difficile</th>
          <th style="color: red;">Résultats Très difficile</th>
          <th rowspan="16"></th>
          <th>Eléments</th>
          <th>Difficulté</th>
          <th>Stat</th>
      </tr>
      <tr>
          <td>1</td>
          <td>1</td>
          <td>0</td>
          <td>0</td>
          <td>0</td>

          <td>Feu</td>
          <td style="color: rgb(255, 150, 0);">Difficile</td>
          <td>Eloquence</td>
      </tr>
      <tr>
          <td>2</td>
          <td>1</td>
          <td>0</td>
          <td>0</td>
          <td>0</td>

          <td>Air</td>
          <td style="color: rgb(255, 150, 0);">Difficile</td>
          <td>Toucher</td>
      </tr>
      <tr>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>0</td>

          <td>Lumière</td>
          <td style="color: rgb(255, 150, 0);">Difficile</td>
          <td>Perception</td>
      </tr>
      <tr>
          <td>4</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>

          <td>Nature</td>
          <td style="color: rgb(255, 0, 0);">Très difficile</td>
          <td>Dégâts</td>
      </tr>
      <tr>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>

          <td>Eau</td>
          <td style="color: rgb(255, 0, 0);">Très difficile</td>
          <td>Défense</td>
      </tr>
      <tr>
          <td>6</td>
          <td>3</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>

          <td>Ténèbre</td>
          <td style="color: rgb(255, 150, 0);">Difficile</td>
          <td>Esquive</td>
      </tr>
      <tr>
          <td>7</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>2</td>

          <td>Tonnerre</td>
          <td style="color: rgb(255, 150, 0);">Difficile</td>
          <td>Agilité</td>
      </tr>
      <tr>
          <td>8</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>2</td>

          <td>Purification</td>
          <td style="color: rgb(255, 150, 0);">Difficile</td>
          <td>Perception magique</td>
      </tr>
      <tr>
          <td>9</td>
          <td>5</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>

          <td>Animaux</td>
          <td style="color: green;">Facile</td>
          <td>N'importe</td>
      </tr>
      <tr>
          <td>10</td>
          <td>5</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>

          <td>Croissance</td>
          <td style="color: green;">Facile</td>
          <td>Force</td>
      </tr>
      <tr>
          <td>11</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
          <td>3</td>

          <td>Esprit</td>
          <td style="color: green;">Facile</td>
          <td>PA</td>
      </tr>
      <tr>
          <td>12</td>
          <td>6</td>
          <td>5</td>
          <td>4</td>
          <td>3</td>

          <td>Âme</td>
          <td style="color: green;">Facile</td>
          <td>Douleur</td>
      </tr>
      <tr>
          <td>13</td>
          <td>7</td>
          <td>6</td>
          <td>4</td>
          <td>3</td>

          <td>Terre</td>
          <td style="color: rgb(255, 150, 0);">Difficile</td>
          <td>Résistance</td>
      </tr>
      <tr>
          <td>14</td>
          <td>7</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>

          <td rowspan="2">Explosion, Glace,<br> Tentacule, Illusion,<br> Eau de vie</td>
          <td rowspan="2">Impossible</td>
          <td rowspan="2">/</td>
      </tr>
      <tr>
          <td>etc</td>
      </tr>
  </table>
</div>

<img style="background-color: rgba(255, 255, 255, 0.5); height: 500px; margin: 50px;" src="../../../assets/images/elements_buffs_transp.png"/>
<br>
