<p *ngIf="langue.l == 'fr'">
    Les Elfes Sombres, humains elfiques, vivant dans la forêt du nord est.<br/>
    Physiquement, ils sont de taille moyenne ou grande, la peau pâle ou grise, les cheveux foncés ou des teintes de gris, les oreilles pointues.
    Un Elf sombre mesure autour de 1m70-1m90.<br/>
    Espérance de vie : 100 ans.<br/>
    Affinité à la magie des ombres (violette)<br/>
    La culture est centrée sur la compétition et la loyauté, le mérite et la liberté.<br/>
    [Thèmes/inspirations : ewok (maisons), amazones (indépendance), ninja (ombres), mafia (gouvernement)]<br/>
    <br/>
    Mentalité<br/>
    Les lois ne sont que des cages dans lesquelles on se met volontairement.<br/>
    L’honneur, un nom donné par les plus faibles à leur incapacité de faire le bon choix.<br/>
    Les promesses n’ont pas de fondement et chaque jour est un jour nouveau duquel on ne peut rien attendre.<br/>
    Chaque elf doit pouvoir survivre par lui-même, sinon il ne le mérite pas.<br/>
    <br/>
    Background<br/>
    Arrivés en Destalyr après les Flyhn et Elfes Sylvains,
    ils se font mépriser par les Elfes Sylvains sans raison apparente,
    et décident d’arrêter ce bullying en déclarant la guerre.
    Après la destruction d’une partie de la forêt, ils signent la trêve  proposée par les Elfes Sylvains.
    Les Elfes Sombres qui ont accepté cette trêve se sont fait insultés par certains de "traîtres" ou de "lâches",
    mais ils ont fait ce sacrifice pour la survie du peuple et celle de la forêt.<br/>
    Ils font de bons mercenaires et assassins, car sans code d’honneur, mais pas très sûr.
    Ils évitent en général les rencontres sociales à cause des préjugés qu’ils subissent.
    Leurs meilleures relations sont avec les Nekali et les Humains qui discriminent moins.
</p>

<p *ngIf="langue.l == 'en'">

</p>
