<br/>
<img class="image-schema" src="../../../assets/images/elements_new_transp.png"/>

<p style="margin-right: 40%;" *ngIf="langue.l == 'fr'">
    Chaque humain a une certaine affité à une magie pure,
    qui sera appellée sa magie majeure, ou plus simplement sa "majeure".
    Certains possèdent une affinité additionnelle envers une autre magie pure,
    qui en devient leur magie mineure, ou simplement "mineure".<br/>
    Quand une personne possède une majeure et une mineure,
    il "débloque" la magie combinée correspondante, selon le diagramme ci-contre.<br/>
    La deuxième affinité arrive plus tard dans la vie de la personne,
    et n'est pas révélée à tout le monde.
    La plupart des grands mages possèdent deux magies pures, mais rien n'empêche l'un d'arriver sans l'autre.<br/>
    <br/>
    Un personnage dans Destalyr va commencer son aventure avec 2 points de spécialisation
    à distribuer sur ses magies, sachant que sa mineure ne peut pas avoir autant ni plus de points que sa majeure (sauf si les deux valent 0).<br/>
    <br/>
    Les points de spécialisation déterminent aussi le nombre de sorts signature que le personnage possède.
    Pour chaque point de spécialisation dans un élément particulier, le personnage gagne un sort signature de cet élément.<br/>
    Un sort signature est un sort magique d'une magie précise, avec des caractéristiques de sort déterminées, et un effet décrit.<br/>
    Par exemple, "lancer un trait de feu", de magie de feu et ayant comme caractéristiques E4-P3-T2-D3, ayant un total de 12.
    Un personnage ayant ce sort signature pourra, lors d'un lancé de dés pour faire un sort, décider d'appliquer ses dés à son sort signature
    sous la condition que la somme totale des dés et des bonus arrive au total du sort (ici 12). Dans ce cas là, le personnage n'a pas besoin de répartir ses dés et ses bonus,
    le sort peut être lancé avec ses propres caractéristiques.
    Si le personnage avait lancé 14 au total de ses dés et bonus, le sort signature sera tout de même lancé tel quel et ses 12 total.<br/>
    Le joueur peut modifier ses sorts signature à chaque montée de niveau.<br/>

</p>
<p style="margin-right: 40%;" *ngIf="langue.l == 'en'">

</p>
