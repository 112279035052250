<p *ngIf="langue.l == 'fr'">
    Les Etreks, humains moitié arbre, vivant autour du lac au sud est.<br/>
    Physiquement, ils sont de taille moyenne ou grande, et des plaques d’écorces remplacent la peau à certains endroits.
    Un Etrek mesure autour de 1m70-1m90.<br/>
    Espérance de vie : 150 ans.<br/>
    Affinité avec la magie d’eau (bleue)<br/>
    La culture est centrée sur la vie et l’entraide, la famille et la générosité.<br/>
    [Thèmes/inspirations : celtique, navigateur, hippie]<br/>
    <br/>
    Mentalité<br/>
    Les Etreks sont très peu nombreux et chaque jour, la race pourrait s’éteindre à cause d’un accident.<br/>
    C’est pour ça que toutes les précautions sont prises lors de constructions de nouveaux bâtiments,
    ou lors d’expéditions hors de la ville.<br/>
    Chaque Etrek porte une part de la responsabilité de la survie de l’espèce,
    et à cause de ça, les suicides sont une source de honte pour la famille et les meurtres impensables.<br/>
    Tout le monde est attendu de former une famille et avoir des enfants, et le nombre d’enfants est représentatif du succès de la famille.
    Les femmes portant plusieurs enfants et qui survivent l'accouchement sont presque vénérées.<br/>
    <br/>
    Background<br/>
    Étant une race directement dérivée des Lyunes, les Etreks se sentent toujours proches d’eux,
    mais gagnent une certaine indépendance au fil du temps.
    La ville principale de leur territoire se trouve au bord du lac sur lequel les premiers Etreks étaient arrivés.
    Autour se trouve la forêt des elfes, Sylvains d’un côté, Sombres de l’autre,
    et le Couloir entre les deux qui lie le territoire lyune à celui des Etreks.
    Ils sont légèrement isolés, et ont été prévenus de la grande guerre plus tard que les autres.
    Sachant qu’elle oppose encore les Sylvains aux Sombres,
    le manque de sûreté du Couloir a été une raison de plus pour la faible présence des Etreks dans la guerre.
</p>

<p *ngIf="langue.l == 'en'">

</p>
